define('client/controllers/offer', ['exports', 'ember', 'client/mixins/detail-controller-mixin', 'client/mixins/model'], function (exports, Ember, DetailController, ModelMix) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		root: 'offers',
		resource: 'offer',
		queryParams: ['edit'],
		categories: ['Ports', 'Phones', 'Interface', 'Services', 'Projektleistungen', 'Standortvernetzung', 'Video', 'CallCenter', 'Hotel', 'Retail', 'Individuell'],
		lost: '',
		r: '',
		range: {
			'min': 10,
			'25%': 25,
			'50%': 50,
			'75%': 75,
			'max': 90
		},
		output: {
			cover: true,
			coverletter: true,
			hotel: false,
			communication: false,
			productdescription: true,
			calculation: true,
			order: false,
			contract: false,
			sla: false,
			portingrequest: false
		},
		url: (function () {
			var model = this.get('model');
			var output = this.get('output');

			var url = this.data.apiUrl + 'offer/' + model.id + '/output/pdf?export=true';
			Ember['default'].$.each(output, function (k, v) {
				if (v) {
					url += '&' + k + '=true';
				}
			});

			url += '&r=' + Date.now();

			return url;
		}).property('r', 'model.id'),
		durations: [{ id: '0', name: 'Ohne Laufzeit' }, { id: '12', name: '12 Monate' }, { id: '24', name: '24 Monate' }, { id: '36', name: '36 Monate' }, { id: '48', name: '48 Monate' }, { id: '60', name: '60 Monate' }],
		isOrder: (function () {
			var model = this.get('model');

			if (model.status == "order") {
				return true;
			}

			return false;
		}).property('model.status'),
		isOwnOrAdmin: (function () {
			var model = this.get('model');
			var user = this.data.user;

			if (model.seller.id == user.id || user.isAdmin) {
				return true;
			}

			return false;
		}).property('model.seller.id'),
		parsePositions: function parsePositions() {
			var model = this.get('model');
			var categories = this.get('categories');
			var output = [];

			Ember['default'].$.each(categories, function (k, category) {

				var obj = { name: category, active: 0, rent: 0, sale: 0, setup: 0, positions: [], collapse: '' };
				if (category == 'Individuell') {
					obj.collapse = 'in';
				}
				Ember['default'].$.each(model.positions, function (k2, v2) {
					if (v2.category == category) {

						if (v2.active) {
							Ember['default'].set(v2, 'opacity', 'opacity:1');
						} else {
							Ember['default'].set(v2, 'opacity', 'opacity:.5');
						}

						Ember['default'].set(v2, 'showDescription', false);

						obj.positions.push(v2);
					}
				});
				output.push(obj);
			});

			return output;
		},
		calculateSubTotals: function calculateSubTotals() {
			var self = this;
			var model = this.get('model');
			var categories = this.get('categories');

			Ember['default'].$.each(categories, function (k, category) {

				var obj = { name: category, active: 0, rent: 0, sale: 0, setup: 0 };
				Ember['default'].$.each(model.positions, function (k2, v2) {
					if (v2.category == category) {
						if (v2.active) {
							obj.active += 1;

							if (v2.amount > 0) {
								if (v2.rent) {
									obj.rent += parseFloat(v2.amount) * parseFloat(v2.values_rent_unitprice);
								}

								obj.setup += parseFloat(v2.amount) * parseFloat(v2.values_setup_unitprice);

								if (v2.sale) {
									obj.sale += parseFloat(v2.amount) * parseFloat(v2.values_sale_unitprice);
								}
							}
						}
					}
				});
				self.set('positions.' + k + '.active', obj.active);
				self.set('positions.' + k + '.rent_sum', obj.rent);
				self.set('positions.' + k + '.setup_sum', obj.setup);
				self.set('positions.' + k + '.sale_sum', obj.sale);
			});
		},
		calculateTotals: function calculateTotals() {
			var self = this;
			var model = this.get('model');

			var totals_monthly_net = 0,
			    totals_monthly_vat = 0,
			    totals_monthly_gross = 0,
			    totals_setup_net = 0,
			    totals_setup_vat = 0,
			    totals_setup_gross = 0,
			    totals_buy_net = 0,
			    totals_buy_vat = 0,
			    totals_buy_gross = 0;

			Ember['default'].$.each(model.positions, function (k, v) {

				var pos_rent_sum = 0,
				    pos_sale_sum = 0,
				    pos_setup_sum = 0;

				if (v.active) {

					self.set('model.positions.' + k + '.opacity', '');

					if (v.amount > 0) {
						if (v.rent) {
							pos_rent_sum = parseFloat(v.amount) * parseFloat(v.values_rent_unitprice);
							totals_monthly_net += pos_rent_sum;
						}

						pos_setup_sum += parseFloat(v.amount) * parseFloat(v.values_setup_unitprice);
						totals_setup_net += pos_setup_sum;

						if (v.sale) {
							pos_sale_sum += parseFloat(v.amount) * parseFloat(v.values_sale_unitprice);
							totals_buy_net += pos_sale_sum;
						}

						self.set('model.positions.' + k + '.pos_rent_sum', pos_rent_sum);
						self.set('model.positions.' + k + '.pos_setup_sum', pos_setup_sum);
						self.set('model.positions.' + k + '.pos_sale_sum', pos_sale_sum);
					}
				} else {
					self.set('model.positions.' + k + '.opacity', 'opacity:.5;');
				}

				self.set('model.positions.' + k + '.rent_dis', v.rent ? 0 : 1);
				self.set('model.positions.' + k + '.sale_dis', v.sale ? 0 : 1);

				if (v.product == Object(v.product) && (v.values_rent_unitprice != v.product.values_rent_default || v.values_sale_unitprice != v.product.values_sale_default || v.values_setup_unitprice != v.product.values_setup_default)) {
					self.set('model.positions.' + k + '.isModified', true);
				} else {
					self.set('model.positions.' + k + '.isModified', false);
				}
			});

			totals_monthly_vat = totals_monthly_net * 0.19;
			totals_setup_vat = totals_setup_net * 0.19;
			totals_buy_vat = totals_buy_net * 0.19;

			totals_monthly_gross = totals_monthly_net * 1.19;
			totals_setup_gross = totals_setup_net * 1.19;
			totals_buy_gross = totals_buy_net * 1.19;

			this.set("totals_monthly_net", totals_monthly_net);
			this.set("totals_monthly_vat", totals_monthly_vat);
			this.set("totals_monthly_gross", totals_monthly_gross);
			this.set("totals_setup_net", totals_setup_net);
			this.set("totals_setup_vat", totals_setup_vat);
			this.set("totals_setup_gross", totals_setup_gross);
			this.set("totals_buy_net", totals_buy_net);
			this.set("totals_buy_vat", totals_buy_vat);
			this.set("totals_buy_gross", totals_buy_gross);
		},
		parseMisc: function parseMisc() {},
		positions: (function () {
			return this.parsePositions();
		}).property('model.positions.@each'),
		count: 0,
		updatePosition: (function () {
			this.calculateTotals();
			this.calculateSubTotals();
			this.parseMisc();
		}).observes('model.positions.@each', 'model.positions.@each.amount', 'model.positions.@each.active', 'model.positions.@each.sale', 'model.positions.@each.rent', 'model.positions.@each.values_rent_unitprice', 'model.positions.@each.values_sale_unitprice', 'model.positions.@each.values_setup_unitprice'),
		actions: {
			changeOpp: function changeOpp(val) {
				this.set('model.opportunity', val);
			},
			changeOppSave: function changeOppSave(val) {
				this.set('model.opportunity', val);
				this.saveItem();
			},
			updateContact: function updateContact() {
				var model = this.get('model');
				var self = this;

				this.api.save('contact', model.recipient.id, { data: JSON.stringify({ briefanrede: model.recipient.briefanrede }) }).then(function (r) {
					self.notify.info(r.message);
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			},
			save: function save() {
				var model = this.get('model');
				var self = this;

				this.api.save('contact', model.recipient.id, { data: JSON.stringify({ briefanrede: model.recipient.briefanrede }) }).then(function () {
					self.saveItem();
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			},
			toggle: function toggle(current, index) {
				if (current == 'in') {
					this.set('positions.' + index + '.collapse', '');
				} else {
					this.set('positions.' + index + '.collapse', 'in');
				}
			},
			resetPrices: function resetPrices(i, p) {
				var pos = this.get('positions.' + i + '.positions.' + p);

				this.set('positions.' + i + '.positions.' + p + '.values_rent_unitprice', parseFloat(pos.product.values_rent_default));
				this.set('positions.' + i + '.positions.' + p + '.values_sale_unitprice', parseFloat(pos.product.values_sale_default));
				this.set('positions.' + i + '.positions.' + p + '.values_setup_unitprice', parseFloat(pos.product.values_setup_default));
			},
			toggleDescription: function toggleDescription(i, p) {
				var pos = this.get('positions.' + i + '.positions.' + p);

				this.set('positions.' + i + '.positions.' + p + '.showDescription', pos.showDescription ? false : true);
			},
			won: function won() {
				var self = this;
				var model = this.get('model');

				this.api.create("offer/" + model.id + "/won").then(function (r) {
					self.notify.info(r.message);
					self.transitionToRoute('order', r.orderId);
				});
			},
			lost: function lost() {
				var self = this;
				var model = this.get('model');

				this.api.create("offer/" + model.id + "/lost", { comment: this.get('lost') }).then(function (r) {
					self.notify.info(r.message);
					self.transitionToRoute('offers', { queryParams: { refresh: Date.now() } });
				});
			},
			addPosition: function addPosition() {
				var pos = ModelMix['default'].create().make({
					name: "Neues Produkt",
					category: "Individuell",
					individuell: true,
					description: "",
					image: "",
					payable: {
						rent: 1,
						sale: 1
					},
					active: 0,
					values_rent_unitprice: 0,
					values_sale_unitprice: 0,
					values_setup_unitprice: 0,
					payment: "rent",
					amount: "0",
					rent: 1,
					sale: 1
				});

				var all = this.get('model.positions');
				all.pushObject(pos);

				this.set('model.positions', all);
			},
			removePosition: function removePosition(pos) {
				var positions = this.get('model.positions');
				positions.removeObject(pos);

				this.set('model.positions', positions);
				this.set('ref', Date.now());
			},
			changeSeller: function changeSeller() {
				var self = this;
				var model = this.get('model');

				this.api.save(this.get('resource'), model.id, { data: JSON.stringify({ seller: model.seller.id }) }).then(function (r) {
					self.notify.info(r.message);
					Ember['default'].$("#viewCS").collapse('toggle');
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			},
			offer: function offer() {
				var output = {
					cover: true,
					coverletter: true,
					hotel: false,
					communication: false,
					productdescription: true,
					calculation: true,
					order: false,
					contract: false,
					sla: false,
					portingrequest: false
				};

				this.set('output', output);
			},
			order: function order() {
				var output = {
					cover: true,
					coverletter: true,
					hotel: false,
					communication: false,
					productdescription: false,
					calculation: false,
					order: true,
					contract: true,
					sla: true,
					portingrequest: false
				};

				this.set('output', output);
			},
			output: function output() {
				var _url = this.get('url');
				var model = this.get('model');
				var self = this;

				this.api.save('contact', model.recipient.id, { data: JSON.stringify({ briefanrede: model.recipient.briefanrede }) }).then(function () {
					self.saveItem(function () {
						self.set('r', Date.now());
						var win = window.open(_url, '_blank');
						win.focus();
					});
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			},
			refresh: function refresh() {
				var model = this.get('model');
				var self = this;

				this.api.save('contact', model.recipient.id, { data: JSON.stringify({ briefanrede: model.recipient.briefanrede }) }).then(function () {
					self.saveItem(function () {
						self.set('r', Date.now());
					});
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			}
		}
	});

});