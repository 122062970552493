define('client/controllers/partner/create', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['partner'],
		resource: 'partner',
		root: 'partner',
		createItem: function createItem() {
			this._super();
			this.get('controllers.partner').set('refresh', Date.now());
		}
	});

});