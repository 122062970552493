define('client/mixins/info-controller-mixin', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		queryParams: ['edit'],
		edit: false,
		actions: {

			toggleEdit: function toggleEdit() {
				this.set('edit', !this.get('edit'));
			},

			save: function save() {
				var self = this;
				var model = this.get('model');

				this.api.save('user', this.data.user.id, { data: JSON.stringify(model) }).then(function (r) {
					self.notify.info(r.message);
					self.set('edit', false);
				})['catch'](function (r) {

					self.notify.info(r.responseJSON.message);
				});
			}

		}
	});

});