define('client/routes/project', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Project',
		model: function model(params) {
			return this.api.query('project', { id: params.id }, ['contacts']).then(function (r) {

				var def = {
					tasks: [],
					locations: [],
					network: [],
					dialplan: [],
					config: {
						start: '',
						end: '',
						notice: ''
					},
					access: {}
				};

				try {
					r.results[0].jsondata = JSON.parse(r.results[0].jsondata);
				} catch (e) {
					r.results[0].jsondata = {};
				}

				r.results[0].jsondata = Ember['default'].$.extend(def, r.results[0].jsondata);

				return Model['default'].create().make(r.results[0]);
			});
		},
		afterModel: function afterModel(model) {
			this.controllerFor('project').getSubItems(model);
		},
		resetController: function resetController(controller, isExiting, transition) {
			controller.set('location', false);
			controller.set('editLocation', false);
			this._super(controller, isExiting, transition);
		}
	});

});