define('client/helpers/status-color', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.statusColor = statusColor;

	function statusColor(status /*, hash*/) {
		var color;

		switch (status) {
			case "opportunity":
				color = 'orange';
				break;
			case "customer":
				color = 'wheat';
				break;
			case "declined":
				color = 'blue';
				break;
			case "deleted":
				color = 'red';
				break;
			case "accepted":
				color = '#00bb00';
				break;
			case "lost":
				color = 'red';
				break;
			case "assigned":
				color = 'grey';
				break;
			case "order":
				color = 'green';
				break;
			case "created":
				color = 'tan';
				break;
			case "draft":
				color = '#ddd';
				break;
			case "open":
				color = 'black';
				break;
			case "paid":
				color = '#00bb00';
				break;
			case "planed":
				color = '#ddd';
				break;
			case "work":
				color = 'yellow';
				break;
			case "finished":
				color = '#00bb00';
				break;
			case "active":
				color = '#00bb00';
				break;
			case "disabled":
				color = '#ddd';
				break;
			case "0":
				color = '#ddd';
				break;
			case "1":
				color = '#00bb00';
				break;
			case 1:
				color = '#00bb00';
				break;
			default:
				color = '#ddd';
				break;

		}

		return new Ember['default'].Handlebars.SafeString(color);
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(statusColor);

});