define('client/tests/unit/helpers/image-helper-test', ['client/helpers/image-helper', 'qunit'], function (image_helper, qunit) {

  'use strict';

  qunit.module('Unit | Helper | image helper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = image_helper.imageHelper(42);
    assert.ok(result);
  });

});