define('client/initializers/io', ['exports'], function (exports) {

	'use strict';

	exports.initialize = initialize;

	function initialize(container, app) {

		// app.register('io:main', window.io, {instantiate: false});
		// app.inject('route:application', 'io', 'io:main');

		app.inject('controller', 'io', 'service:io');
		app.inject('route', 'io', 'service:io');
	}

	exports['default'] = {
		name: 'io',
		after: 'api',
		initialize: initialize
	};

});