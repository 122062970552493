define('client/helpers/image-helper', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.imageHelper = imageHelper;

	function imageHelper(image /*, hash*/) {
		if (image === undefined || image === "") {
			return 'dummy.png';
		}
		return image;
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(imageHelper);

});