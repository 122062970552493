define('client/views/application', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].View.extend({
        tagName: '',
        didInsertElement: function didInsertElement() {
            this._super();
            Ember['default'].run.scheduleOnce('afterRender', this, this.afterRenderEvent);
        },
        afterRenderEvent: function afterRenderEvent() {
            window.setResponsive();
            Ember['default'].$('.sidebar-nav .treeview').tree();
        }
    });

});