define('client/routes/contacts/contact', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Contact',
		model: function model(params) {
			return this.api.query('contact', { id: params.id }, ["offers", "orders", "user", "projects", "invoices", "activity"]).then(function (r) {
				return Model['default'].create().make(r.results[0]);
			});
		}
	});

});