define('client/components/x-toggle/component', ['exports', 'ember-cli-toggle/components/x-toggle/component', 'client/config/environment'], function (exports, XToggle, ENV) {

  'use strict';

  var config = ENV['default']['ember-cli-toggle'] || {};

  exports['default'] = XToggle['default'].extend({
    theme: config.defaultTheme || 'default',
    off: config.defaultOff || 'Off',
    on: config.defaultOn || 'On',
    showLabels: config.defaultShowLabels || false,
    size: config.defaultSize || 'medium'
  });

});