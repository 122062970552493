define('client/components/input-number', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		tagName: 'input',
		attributeBindings: ['type', 'disabled', 'value', 'tabindex', 'name', 'autofocus', 'required'],
		type: 'text',
		sign: ' €',
		t: null,
		renderValue: function renderValue() {
			var number = this.get('number');
			var reg = /[,]/;

			if (reg.test(number) === true) {
				return number;
			}

			return parseFloat(number).formatNumber(2, '.', ',') + this.get('sign');
		},
		value: (function () {
			return this.renderValue();
		}).property(),
		updateValue: (function () {

			var self = this;
			clearTimeout(this.get('t'));

			this.set('t', setTimeout(function () {
				self.set('value', self.renderValue());
			}, 1000));
		}).observes('number'),
		didInsertElement: function didInsertElement() {

			this._super();
			var self = this;

			this.$().on("change paste keyup", function () {

				var input = Ember['default'].$(this).val();

				if (typeof input === 'undefined') {
					input = 0.00;
				}

				var r1 = /[^0-9-,]/;
				var r2 = /[,]/;

				if (r1.test(input) === true) {
					input = input.replace(r1, '');
				}

				if (r2.test(input) === true) {
					input = input.replace(r2, '.');
					input = parseFloat(input);
				}

				self.set('number', input);
			});
		}
	});

});