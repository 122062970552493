define('client/controllers/contacts/contact/index', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['contacts'],
		root: 'contacts',
		queryParams: ['edit'],
		resource: 'contact',
		removeItem: function removeItem() {
			this._super();
			this.get('controllers.contacts').set('refresh', Date.now());
		},
		callcenter: {
			comment: null,
			date: moment()
		},
		todo: {
			subject: null,
			date: moment(),
			user_id: null,
			body: null
		},
		act: {
			subject: null,
			date: moment(),
			body: null
		},
		stati: ['opportunity', 'customer', 'declined', 'deleted', 'accepted', 'lost', 'assigned', 'order', 'created'],
		users: (function () {
			return this.data.get('users');
		}).property(),
		isCallcenter: (function () {
			var model = this.get('model');
			if (model.status == "callcenter") {
				return true;
			}
			return false;
		}).property('model.status'),
		isAllowedToAccept: (function () {
			var model = this.get('model');
			if (this.data.user.id == model.user_id && model.status == "assigned") {
				return true;
			}
			return false;
		}).property('model.user_id', 'model.status'),
		isAllowedToActivate: (function () {
			var model = this.get('model');
			if (this.data.user.id == model.user_id && model.status == "deleted") {
				return true;
			}
			if (this.data.user.isAdmin && model.status == "deleted") {
				return true;
			}
			return false;
		}).property('model.user_id', 'model.status'),
		isAllowedToLost: (function () {
			var model = this.get('model');
			if (model.status == "accepted" || model.status == 'opportunity') {
				return true;
			}
			return false;
		}).property('model.status'),
		isOwn: (function () {
			var model = this.get('model');

			if (this.data.user.id == model.user_id && (model.status == "assigned" || model.status == 'accepted' || model.status == 'created')) {
				return true;
			}

			if (this.data.user.isAdmin) {
				return true;
			}

			return false;
		}).property('model.user_id', 'model.status'),
		reloadActivity: function reloadActivity() {
			var model = this.get('model');
			var self = this;

			this.api.query('activity', { "contact_id": { "eq": model.id, "op": "AND" }, "activitytype": { "nin": ['log'], "op": "AND" } }, []).then(function (r) {
				model.set('activity', r.results);
				self.set('model', model);
				self.controllerFor('contacts.contact.activities').set('model', model);
				self.cache.setCache('contact', model);
			});
		},
		actions: {
			acceptLead: function acceptLead() {
				var self = this;
				var model = this.get('model');
				model.set('status', 'accepted');

				this.api.save("contact/" + model.id + "/accept").then(function (r) {
					self.notify.info(r.message);
					self.set('model', model);
					self.transitionToRoute('contacts');
				});
			},
			declineLead: function declineLead() {
				var self = this;
				var model = this.get('model');

				model.set('status', 'declined');

				this.api.save("contact/" + model.id + "/decline", false, { comment: this.get('declineComment') }).then(function (r) {
					self.notify.info(r.message);
					self.set('model', model);
					self.transitionToRoute('contacts');
				});
			},
			passTo: function passTo() {
				var self = this;
				var model = this.get('model');

				if (this.get('passTo')) {

					model.set('status', 'assigned');
					model.set('user_id', this.get('passTo'));

					this.api.save("contact/" + model.id + "/move/" + this.get('passTo')).then(function (r) {
						self.notify.info(r.message);
						self.set('model', model);
						self.transitionToRoute('contacts');
					});
				}
			},
			getFromCallcenter: function getFromCallcenter() {
				var model = this.get('model');
				var self = this;
				model.set('status', 'returned');

				this.api.save("contact", model.id, { data: JSON.stringify(model) }).then(function (r) {
					self.notify.info(r.message);
					self.set('model', model);
				})['catch'](function (r) {
					self.notify.info(r.message);
				});
			},
			toCallcenter: function toCallcenter() {
				var callcenter = this.get('callcenter');
				var model = this.get('model');
				var self = this;

				if (callcenter.date.length < 8) {
					this.notify.warning('Datumfeld darf nicht leer sein');
					return;
				}

				model.set('status', 'callcenter');
				this.api.save("contact", model.id, { data: JSON.stringify(model) }).then(function (r) {
					self.notify.info(r.message);
					self.set('model', model);
				})['catch'](function (r) {
					self.notify.info(r.message);
				});

				this.api.create("activity", { data: JSON.stringify({ body: callcenter.comment, subject: 'Back to Callcenter', createdAt: moment().format("YYYY-MM-DD HH:mm:ss"), activitytype: "callcenter", assignedTo: model.createdBy, user_id: this.data.user.id, done: 0, contact_id: model.id, dateStart: callcenter.date }) }).then(function () {
					self.notify.info('Pushed item back to callcenter');
					self.reloadActivity();
				});
			},
			markLost: function markLost() {
				var model = this.get('model');
				var self = this;

				this.api.save("contact/" + model.id + "/lost", false, { comment: this.get('lostComment') }).then(function (r) {
					self.notify.info(r.message);
				});
			},
			activate: function activate() {
				var model = this.get('model');
				var self = this;

				this.api.save("contact/" + model.id + "/status/unassigned").then(function (r) {
					self.notify.info(r.message);
					model.set('status', "unassigned");
					self.set('model', model);
				});
			},
			saveStatus: function saveStatus() {
				this.saveItem();
			},
			saveAct: function saveAct() {
				var item = this.get('act');
				var model = this.get('model');
				var self = this;

				this.api.create("contact/" + model.id + "/history", { body: item.body, subject: item.subject, createdAt: item.date.toString(), activitytype: "comment" }).then(function (r) {
					self.notify.info(r.message);
					self.set('act', {});
					$("#viewGew").collapse('toggle');
					self.reloadActivity();
				});
			},
			saveTodo: function saveTodo() {
				var item = this.get('todo');
				var model = this.get('model');
				var self = this;

				this.api.create("contact/" + model.id + "/history", { body: item.body, subject: item.subject, dateStart: item.date.toString(), done: "0", activitytype: "todo", assignedTo: item.user_id, user_id: item.user_id }).then(function (r) {
					self.notify.info(r.message);
					self.set('todo', {});
					$("#viewTodo").collapse('toggle');
					self.reloadActivity();
				});
			}
		}

	});

});