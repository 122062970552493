define('client/views/search', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({
		templateName: 'views/search',
		placeholder: (function () {

			if (!this.get('context').get('filter') || this.get('context').get('filter').length === 0 || this.get('context').get('filter') == 'null' || this.get('context').get('filter') === false) {
				return '...';
			} else {
				return 'Suche im ' + this.get('context').get('filter') + '-Filter';
			}
		}).property('context.filter')
	});

});