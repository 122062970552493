define('client/controllers/partner/partner', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['partner'],
		root: 'partner',
		queryParams: ['edit'],
		resource: 'partner',
		removeItem: function removeItem() {
			this._super();
			this.get('controllers.partner').set('refresh', Date.now());
		}

	});

});