define('client/controllers/activities', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin', 'client/mixins/model-array'], function (exports, Ember, SearchFilterController, ModelArray) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ["user", "contacts"],
        queryParams: ['sort', 'page', 'filter', 'query', 'calendar', 'refresh'],
        filter: 'Wichtiges',
        resource: 'activity',
        user: (function () {
            return this.data.user.id;
        }).property(),
        fields: ["user.name", "user.mail", "contacts.firma", "contacts.name", "subject", "body", "createdAt", "modifiedAt"],
        filterNames: ['Wichtiges', '', 'Callcenter', 'Kommentare', 'Log', 'Login', 'Mail', 'PDF', 'Ticket', 'Todo', 'Work'],
        filterQueries: (function () {
            var user = this.get('user');

            if (user) {
                return {
                    'Wichtiges': { "activitytype": { "nin": ['log', 'pdf', 'mail', 'login'], "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Callcenter': { "activitytype": { "eq": 'callcenter', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Kommentare': { "activitytype": { "eq": 'comment', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Log': { "activitytype": { "eq": 'log', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Login': { "activitytype": { "eq": 'login', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Mail': { "activitytype": { "eq": 'mail', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'PDF': { "activitytype": { "eq": 'pdf', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Ticket': { "activitytype": { "eq": 'ticket', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Todo': { "activitytype": { "eq": 'todo', "op": "AND" }, "done": { "eq": '0', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } },
                    'Work': { "activitytype": { "eq": 'work', "op": "AND" }, "assignedTo": { "eq": user, "op": "AND" } }
                };
            } else {
                return {
                    'Wichtiges': { "activitytype": { "nin": ['log', 'pdf', 'mail'], "op": "AND" } },
                    'Callcenter': { "activitytype": { "eq": 'callcenter', "op": "AND" } },
                    'Kommentare': { "activitytype": { "eq": 'comment', "op": "AND" } },
                    'Log': { "activitytype": { "eq": 'log', "op": "AND" } },
                    'Login': { "activitytype": { "eq": 'login', "op": "AND" } },
                    'Mail': { "activitytype": { "eq": 'mail', "op": "AND" } },
                    'PDF': { "activitytype": { "eq": 'pdf', "op": "AND" } },
                    'Ticket': { "activitytype": { "eq": 'ticket', "op": "AND" } },
                    'Todo': { "activitytype": { "eq": 'todo', "op": "AND" }, "done": { "eq": '0', "op": "AND" } },
                    'Work': { "activitytype": { "eq": 'work', "op": "AND" } }
                };
            }
        }).property('user'),
        limit: (function () {
            if (this.get('calendar')) {
                return 100000;
            } else {
                return 50;
            }
        }).property('calendar'),
        calendar: false,
        buildCalendarFilter: function buildCalendarFilter() {
            var from = this.get('from');
            var to = this.get('to');

            var filter = this.get('filterQueries')[this.get('filter')];
            if (typeof filter === 'undefined' || !filter) {
                return { "": { "dateStart": { "gte": from, "op": "AND" }, "dateEnd": { "lte": to, "op": "AND" } }, "OR": { "createdAt": { "gte": from, "op": "AND" }, "": { "createdAt": { "lte": to, "op": "AND" } } } };
            }
            return { "": { "": { "dateStart": { "gte": from, "op": "AND" }, "dateEnd": { "lte": to, "op": "AND" } }, "OR": { "createdAt": { "gte": from, "op": "AND" }, "": { "createdAt": { "lte": to, "op": "AND" } } } }, "AND": filter };
        },
        buildMethod: function buildMethod() {
            if (this.get('calendar')) {
                return this.buildCalendarFilter();
            } else {
                return this.buildQuery();
            }
        },
        changeUser: (function () {
            this.send('load');
        }).observes('user'),
        actions: {
            toggleCalendar: function toggleCalendar() {
                this.set('calendar', !this.get('calendar'));
                if (this.get('calendar')) {
                    this.set('query', '');
                }
            },
            toggleUser: function toggleUser() {
                if (this.get('user')) {
                    this.set('user', false);
                } else {
                    this.set('user', this.data.user.id);
                }
            },
            eventClick: function eventClick(calEvent /*, jsEvent, view*/) {
                this.transitionToRoute('activities.activity', calEvent);
            },
            loadEvents: function loadEvents(view) {
                this.set('from', view.intervalStart);
                this.set('to', view.intervalEnd);
                this.send('load');
            },
            search: function search() {
                this.set('calendar', false);
                this.send('load');
            },
            load: function load() {
                var self = this;
                this.set('page', 1);

                this.api.query(this.get('resource'), this.buildMethod(), this.get('includes'), 1, this.get('limit')).then(function (r) {
                    self.set('count', r.count);
                    self.set('model', ModelArray['default'].create().make(r.results));
                })['catch'](function () {});
            },
            resetFilter: function resetFilter() {
                this.set('filter', null);
                this.send('load');
            },
            performFilter: function performFilter(input) {
                this.set('filter', input);
                this.send('load');
            }
        }
    });

});