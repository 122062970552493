define('client/controllers/index', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		actions: {
			io: function io() {

				console.log(this.data.user);
			}
		}
	});

});