define('client/routes/users/user', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'User',
		model: function model(params) {
			return this.api.query('user', { id: params.id }, ["activity", "projects", "orders", "offers", "invoices", "skills", "company"]).then(function (r) {
				return Model['default'].create().make(r.results[0]);
			});
		},
		afterModel: function afterModel(item) {

			var self = this;

			this.api.query('partner', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('users.user.index').set('partner', r.results);
			});

			this.cache.setCache('user', item);
			this._super();
		}
	});

});