define('client/tests/unit/helpers/just-date-test', ['client/helpers/just-date', 'qunit'], function (just_date, qunit) {

  'use strict';

  qunit.module('JustDateHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = just_date.justDate(42);
    assert.ok(result);
  });

});