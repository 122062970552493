define('client/controllers/password', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		actions: {
			forgot: function forgot() {
				var self = this;

				this.api.post("user/forgotPassword", { mail: this.get('mail') }).then(function () {
					self.notify.info('Sie erhalten in Kürze eine E-Mail mit einem Link');
				})['catch'](function (a) {
					var res = JSON.parse(a.responseText);
					self.notify.error(res.message);
				});
			}
		}
	});

});