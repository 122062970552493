define('client/helpers/extension-type', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.extensionType = extensionType;

    function extensionType(model /*, hash*/) {

        var DeskPhones = ["1608", "1616", "4610", "4612CL", "4620", "4621", "6408", "6408D+", "9608", "9608SIP", "9608SIPCC", "9611", "9611SIP", "9620", "9621", "9620SIP", "9630", "9630SIP", "9640", "9640SIP", "9641", "9650", "9650SIP", "9408"];
        var Analog = ["2500", "CallrID"];
        var Vdn = ["vdn"];
        var Agent = ["agent"];
        var UM = ["um"];
        var out;

        if (DeskPhones.indexOf(model.type) !== -1) {
            out = 'DeskPhone';
        } else if (Analog.indexOf(model.type) !== -1) {
            out = 'Analog';
        } else if (Vdn.indexOf(model.type) !== -1) {
            out = 'Virtual';
        } else if (Agent.indexOf(model.type) !== -1) {
            out = 'Agent';
        } else if (UM.indexOf(model.type) !== -1) {
            out = 'Unified Messaging';
        } else {
            out = model.type;
        }

        return new Ember['default'].Handlebars.SafeString(out);
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(extensionType);

});