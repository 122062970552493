define('client/controllers/contacts', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin'], function (exports, Ember, SearchFilterController) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ["offers", "orders", "user", "projects", "activity", "invoices"],
        filter: 'Standard',
        resource: 'contact',
        //preFilter: {active: {eq:1, op:"AND"}},
        fields: ["firma", "name", "anrede", "ort"],
        filterNames: ['Standard', '', 'Inbox', 'Leads', 'Opportunities', 'Kunden', 'Verloren', '', 'Lead Pool', 'zugewiesene Leads', 'angenommene Leads'],
        filterQueries: (function () {
            return {
                'Standard': { "": { status: { "nin": ["deleted", "lost", "callcenter"], "op": "AND" } } },
                'Inbox': { "": { user_id: { "eq": this.data.user.id, "op": "AND" }, status: { "eq": "assigned", "op": "AND" } } },
                'Leads': { "": { user_id: { "eq": this.data.user.id, "op": "AND" }, status: { "nin": ["deleted", "callcenter", "returned", "lost", "assigned", "customer", "declined"], "op": "AND" } } },
                'Opportunities': { "": { user_id: { "eq": this.data.user.id, "op": "AND" }, status: { "eq": "opportunity", "op": "AND" } } },
                'Kunden': { "": { user_id: { "eq": this.data.user.id, "op": "AND" }, status: { "eq": "customer", "op": "AND" } } },
                'Verloren': { "": { user_id: { "eq": this.data.user.id, "op": "AND" }, status: { "eq": "lost", "op": "AND" } } },
                'Lead Pool': { "status": "unassigned" },
                'zugewiesene Leads': { "status": "assigned" },
                'angenommene Leads': { "status": "accepted" }
            };
        }).property(),
        limit: 50
    });

});