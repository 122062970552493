define('client/initializers/data', ['exports'], function (exports) {

	'use strict';

	exports.initialize = initialize;

	function initialize(container, app) {

		app.inject('route', 'data', 'service:data');
		app.inject('controller', 'data', 'service:data');
		app.inject('component', 'data', 'service:data');
		app.inject('adapter', 'data', 'service:data');
	}

	exports['default'] = {
		name: 'data',
		before: 'api',
		initialize: initialize
	};

});