define('client/routes/products/create', ['exports', 'ember', 'client/mixins/detail-route-mixin'], function (exports, Ember, DetailRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		model: function model() {
			return {};
		}
	});

});