define('client/controllers/products/create', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['products'],
		resource: 'product',
		root: 'products',
		categories: ['Ports', 'Phones', 'Interface', 'Services', 'Projektleistungen', 'Standortvernetzung', 'Video', 'CallCenter', 'Hotel'],
		createItem: function createItem() {
			this._super();
			this.get('controllers.products').set('refresh', Date.now());
		}
	});

});