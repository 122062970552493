define('client/tests/unit/helpers/extension-type-test', ['client/helpers/extension-type', 'qunit'], function (extension_type, qunit) {

  'use strict';

  qunit.module('ExtensionTypeHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = extension_type.extensionType(42);
    assert.ok(result);
  });

});