define('client/routes/offers/create', ['exports', 'ember', 'client/mixins/detail-route-mixin'], function (exports, Ember, DetailRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		model: function model() {
			return {
				opportunity: 0,
				duration: 0,
				date: moment()
			};
		}
	});

});