define('client/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'client/config/environment', 'client/helpers/translate'], function (exports, Ember, Resolver, loadInitializers, config, translateHelper) {

	'use strict';

	var App = undefined;

	Ember['default'].Handlebars.helper('translate', translateHelper['default']);

	if (config['default'].environment === 'production') {
		Ember['default'].deprecate = function () {};
		Ember['default'].warn = function () {};
	}

	Ember['default'].MODEL_FACTORY_INJECTIONS = true;

	App = Ember['default'].Application.extend({
		modulePrefix: config['default'].modulePrefix,
		podModulePrefix: config['default'].podModulePrefix,
		Resolver: Resolver['default']
	});

	loadInitializers['default'](App, config['default'].modulePrefix);

	exports['default'] = {
		create: function create() {

			var options = {
				type: 'GET'
			};

			options.xhrFields = {
				withCredentials: true
			};

			Ember['default'].$.ajax(config['default'].apiUrl + 'user/me', options).then(function (r) {
				App = App.extend({
					init: function init() {
						this._super.apply(this, arguments);
						var app = this.__container__;

						var data = app.lookup('service:data');
						var lang = 'de';

						data.set('lang', lang);
						moment.locale(lang);
						data.set('user', r.results);
						data.set('overlay', 'layouts/login');
						data.set('online', true);
						data.set('apiUrl', config['default'].apiUrl);
						data.set('baseUrl', config['default'].baseUrl);
					}
				});
				App.create();
			}).fail(function () {
				App = App.extend({
					init: function init() {
						this._super.apply(this, arguments);
						var app = this.__container__;

						var data = app.lookup('service:data');
						var lang = 'de';

						data.set('lang', lang);
						moment.locale(lang);
						data.set('user', false);
						data.set('overlay', 'layouts/main');
						data.set('online', false);
						data.set('apiUrl', config['default'].apiUrl);
						data.set('baseUrl', config['default'].baseUrl);
					}
				});
				App.create();
			});
		}
	};

});