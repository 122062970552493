define('client/helpers/just-date', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.justDate = justDate;

	function justDate(date /*, hash*/) {
		if (moment(date).isValid()) {
			moment.locale('de');
			return moment(date).format('L LT');
		}

		return '-';
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(justDate);

});