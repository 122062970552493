define('client/helpers/button-function-name', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.buttonFunctionName = buttonFunctionName;

    function buttonFunctionName(id /*, hash*/) {

        var res;

        switch (id) {
            case "call-appr":
                res = 'Leitungstaste';
                break;
            case "call-fwd":
                res = 'Rufweiterleitung';
                break;
            case "dn-dst":
                res = 'Nicht Stören';
                break;
            case "ec500":
                res = 'EC500';
                break;
            case "voice-mail":
                res = 'Voice Mail';
                break;
            default:
                res = id;

        }

        return new Ember['default'].Handlebars.SafeString(res);
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(buttonFunctionName);

});