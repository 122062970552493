define('client/tests/unit/helpers/status-list-test', ['client/helpers/status-list', 'qunit'], function (status_list, qunit) {

  'use strict';

  qunit.module('StatusListHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = status_list.statusList(42);
    assert.ok(result);
  });

});