define('client/routes/home', ['exports', 'ember', 'client/mixins/authentication'], function (exports, Ember, AuthenticationMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticationMixin['default'], {
		titleToken: 'Home',
		model: function model() {

			var self = this;
			return this.api.find('dashboard').then(function (r) {
				self.controllerFor('home').set('dashboard', r.results);
			});
		},
		afterModel: function afterModel() {
			var self = this;

			// locations
			this.api.count('location', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('locations', r.count);
			});
		}
	});

});