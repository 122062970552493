define('client/routes/application', ['exports', 'ember', 'client/config/environment'], function (exports, Ember, AppConfig) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		title: function title(tokens) {
			if (!tokens) {
				return AppConfig['default'].title;
			}
			return tokens.join(' | ') + ' | ' + AppConfig['default'].title;
		},
		beforeModel: function beforeModel() {

			if (this.data.online) {

				//this.io.connect(this.controllerFor('io'), this.data.user);

			}
		}
	});

});