define('client/components/full-calendar', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['full-calendar'],

    // Event Data
    events: null,

    // General Display
    headerLeft: 'month,agendaWeek,agendaDay',
    headerCenter: 'title',
    headerRight: 'today prev,next',
    theme: false,
    firstDay: 1,
    isRTL: false,
    weekends: true,
    hiddenDays: [],
    fixedWeekCount: true,
    weekNumbers: false,
    height: 'auto',

    // Event Dragging & Resizing
    editable: false,
    eventStartEditable: false,
    eventDurationEditable: false,
    dragRevertDuration: 500,
    dragOpacity: 0.75,
    dragScroll: true,
    dragOverlap: true,

    // Selecting
    selectable: false,

    getEvents: function getEvents() {
      var events = this.get('events');
      Ember['default'].$.each(events, function (k, v) {
        events[k].title = v.subject;
        events[k].start = moment(v.createdAt);

        if (v.dateStart && moment(v.dateStart).isValid()) {
          events[k].start = moment(v.dateStart);
          if (v.dateEnd && moment(v.dateEnd).isValid()) {
            events[k].start = moment(v.dateEnd);
          }
        }

        if (v.dateDone && moment(v.dateDone).isValid()) {
          events[k].backgroundColor = '#0F3F67';
        }
      });

      return events;
    },

    updateEvents: (function () {
      var fullCalendarComponent = Ember['default'].$(".full-calendar");
      fullCalendarComponent.fullCalendar('removeEvents');
      fullCalendarComponent.fullCalendar('addEventSource', this.getEvents());
      fullCalendarComponent.fullCalendar('rerenderEvents');
    }).observes('events'),

    _initializeCalendar: (function () {
      var _this = this;
      return Ember['default'].$(".full-calendar").fullCalendar({
        // General Display
        header: {
          left: _this.get('headerLeft'),
          center: _this.get('headerCenter'),
          right: _this.get('headerRight')
        },

        lang: 'de',

        timezone: 'Europe/Berlin',

        timeFormat: 'H(:mm)',

        firstDay: 1,

        startParam: 'dateStart',

        theme: _this.get('theme'),

        // Event Data
        events: _this.getEvents(),

        // Clicking & Hovering
        eventClick: function eventClick(calEvent, jsEvent, view) {
          _this.sendAction('eventClick', calEvent, jsEvent, view);
        },

        eventDragStart: function eventDragStart(event, jsEvent, ui, view) {
          _this.sendAction('eventDragStart', event, jsEvent, ui, view);
        },

        eventDragStop: function eventDragStop(event, jsEvent, ui, view) {
          _this.sendAction('eventDragStart', event, jsEvent, ui, view);
        },

        eventDrop: function eventDrop(event, delta, revertFunc, jsEvent, ui, view) {
          _this.sendAction('eventDrop', event, delta, revertFunc, jsEvent, ui, view);
        },

        eventResize: function eventResize(event, delta, revertFunc, jsEvent, ui, view) {
          _this.sendAction('eventResize', event, delta, revertFunc, jsEvent, ui, view);
        },

        eventResizeStart: function eventResizeStart(event, jsEvent, ui, view) {
          _this.sendAction('eventResizeStart', event, jsEvent, ui, view);
        },

        eventResizeStop: function eventResizeStop(event, jsEvent, ui, view) {
          _this.sendAction('eventResizeStop', event, jsEvent, ui, view);
        },

        select: function select(start, end, jsEvent, view) {
          _this.sendAction('select', start, end, jsEvent, view);
        },

        viewRender: function viewRender(view, element) {
          _this.sendAction('loadEvents', view, element);
        },

        // Dragging & Resizing
        editable: _this.get('editable'),
        eventStartEditable: _this.get('eventStartEditable'),
        eventDurationEditable: _this.get('eventDurationEditable'),
        dragRevertDuration: _this.get('dragRevertDuration'),
        dragOpacity: _this.get('dragOpacity'),
        dragScroll: _this.get('dragScroll'),

        // Selecting
        selectable: _this.get('selectable')
      });
    }).on('didInsertElement').observes('events')
  });

});