define('client/helpers/status-list', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.statusList = statusList;

    function statusList() /*params, hash*/{
        return ['opportunity', 'customer', 'declined', 'deleted', 'accepted', 'lost', 'assigned', 'order', 'created', 'draft', 'open', 'paid', '0', '1', ''];
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(statusList);

});