define('client/controllers/project', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		root: 'projects',
		resource: 'project',
		includes: ['numbers', 'lines'],
		queryParams: ['edit'],
		site_location: null,
		loadUsers: function loadUsers(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('user', {}, [], 1, 10000).then(function (r) {
				self.set("users", r.results);
			});
		},
		loadContracts: function loadContracts(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('contract', {}, [], 1, 10000).then(function (r) {
				self.set("contracts", r.results);
			});
		},
		loadAccess: function loadAccess(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('project/access', { project_id: model.id }, ["user"], 1, 10000).then(function (r) {
				self.set("access", r.results);
			});
		},
		loadTodos: function loadTodos(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('activity', { project_id: { "eq": model.id, "op": "AND" }, done: { "eq": "0", "op": "AND" }, activitytype: { "ne": "work", "op": "AND" } }, ["contacts", "user", "projects", "offers"], 1, 10000).then(function (r) {
				self.set("todos", r.results);
			});
		},
		loadConnections: function loadConnections(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('connections', { project_id: { "eq": model.id, "op": "AND" } }, [], 1, 10000).then(function (r) {
				self.set("connections", r.results);
			});
		},
		loadSites: function loadSites(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('site', { project_id: { "eq": model.id, "op": "AND" } }, ['location'], 1, 10000).then(function (r) {
				self.set("sites", r.results);
			});
		},
		loadNumbers: function loadNumbers(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('number', { project_id: { "eq": model.id, "op": "AND" } }, ['contract'], 1, 10000).then(function (r) {
				self.set("numbers", r.results);
			});
		},
		loadLocations: function loadLocations(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('location', { project_id: { "eq": model.id, "op": "AND" } }, [], 1, 10000).then(function (r) {
				Ember['default'].$.each(r.results, function (k, v) {
					try {
						r.results[k].jsondata = JSON.parse(v.jsondata);
					} catch (e) {
						r.results[k].jsondata = {};
					}
				});
				self.set("locations", r.results);
			});
		},
		loadExtensions: function loadExtensions(model) {
			var self = this;
			model = model || this.get('model');
			this.api.query('extension', { project_id: { "eq": model.id, "op": "AND" } }, [], 1, 10000).then(function (r) {
				Ember['default'].$.each(r.results, function (k, v) {
					try {
						r.results[k].jsondata = JSON.parse(v.jsondata);
					} catch (e) {
						r.results[k].jsondata = {};
					}
				});
				self.set("extensions", r.results);
			});
		},
		getSubItems: (function (model) {
			this.loadUsers(model);
			this.loadAccess(model);
			this.loadTodos(model);
			this.loadConnections(model);
			this.loadSites(model);
			this.loadNumbers(model);
			this.loadLocations(model);
			this.loadExtensions(model);
			this.loadContracts(model);
		}).observes('refresh'),
		updateSiteLocation: (function () {
			var loc = this.get('site.location');
			if (loc) {
				this.set('site.location_prefix', loc.id);
			} else {
				try {
					this.set('site.location_prefix', null);
				} catch (e) {}
			}
		}).observes('site.location'),
		updateNumberContract: (function () {
			var loc = this.get('number.contract');
			if (loc) {
				this.set('number.contract_id', loc.id);
			} else {
				try {
					this.set('number.contract_id', null);
				} catch (e) {}
			}
		}).observes('number.contract'),
		actions: {
			save: function save() {
				var self = this;
				var model = this.get('model');
				var compared = this.cache.compare(this.get('resource'), model);
				compared.jsondata = JSON.stringify(compared.jsondata);

				this.api.save(this.get('resource'), model.id, { data: JSON.stringify(compared) }).then(function (r) {
					self.notify.info(r.message);
				})['catch'](function (r) {
					self.notify.error(r.message);
				});

				this.set('edit', false);
			},
			addAccess: function addAccess() {
				var model = this.get('model');
				var user = this.get('user');
				var self = this;

				this.api.save('project/' + model.id + '/access', user.id).then(function (r) {
					self.notify.info(r.message);
					self.loadAccess();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			removeAccess: function removeAccess(item) {
				var model = this.get('model');
				var self = this;

				this.api.remove('project/' + model.id + '/access', item.user.id).then(function (r) {
					self.notify.info(r.message);
					self.get('access').removeObject(item);
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			showLocation: function showLocation(item) {
				this.set('location', item);
			},

			saveSite: function saveSite() {
				var site = this.get('site');
				var self = this;

				this.api.save('site', site.id, { data: JSON.stringify(site) }).then(function (r) {
					self.notify.info(r.message);
					self.set('site', null);
					self.loadSites();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			addSite: function addSite() {
				var site = this.get('site');
				var self = this;
				site.project_id = this.get('model.id');

				this.api.create('site', { data: JSON.stringify(site) }).then(function (r) {
					self.notify.info(r.message);
					self.set('site', null);
					self.set('siteAdd', false);
					self.loadSites();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			removeSite: function removeSite(item) {
				var self = this;

				this.api.remove('site', item.id).then(function (r) {
					self.notify.info(r.message);
					self.set('site', null);
					self.get('sites').removeObject(item);
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			showSite: function showSite(item) {
				this.set('site', item);
				this.set('siteAdd', false);
			},
			showAddSite: function showAddSite() {
				this.set('site', {});
				this.set('siteAdd', true);
			},
			cancelSite: function cancelSite() {
				this.set('site', null);
				this.set('siteAdd', false);
			},

			saveNumber: function saveNumber() {
				var number = this.get('number');
				var self = this;

				this.api.save('number', number.id, { data: JSON.stringify(number) }).then(function (r) {
					self.notify.info(r.message);
					self.set('number', null);
					self.loadNumbers();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			addNumber: function addNumber() {
				var number = this.get('number');
				var self = this;
				number.project_id = this.get('model.id');

				this.api.create('number', { data: JSON.stringify(number) }).then(function (r) {
					self.notify.info(r.message);
					self.set('number', null);
					self.set('numberAdd', false);
					self.loadNumbers();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			removeNumber: function removeNumber(item) {
				var self = this;
				this.api.remove('number', item.id).then(function (r) {
					self.notify.info(r.message);
					self.set('number', null);
					self.get('numbers').removeObject(item);
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			showNumber: function showNumber(item) {
				this.set('number', item);
				this.set('numberAdd', false);
			},
			showAddNumber: function showAddNumber() {
				this.set('number', {});
				this.set('numberAdd', true);
			},
			cancelNumber: function cancelNumber() {
				this.set('number', null);
				this.set('numberAdd', false);
			}
		}
	});

});