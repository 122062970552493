define('client/components/check-box', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		tagName: 'input',
		attributeBindings: ['type', 'disabled', 'checked', 'tabindex', 'name', 'autofocus', 'required'],
		type: 'checkbox',
		checked: (function () {
			if (this.get('value') === "1" || this.get('value') === 1 || this.get('value') === true) {
				return true;
			} else {
				return false;
			}
		}).property('value'),
		didInsertElement: function didInsertElement() {
			this._super();

			var self = this;

			this.$().change(function () {
				if (self.$().is(":checked")) {
					self.set('value', "1");
				} else {
					self.set('value', "0");
				}
			});
		}
	});

});