define('client/controllers/partner', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin'], function (exports, Ember, SearchFilterController) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ['user'],
        filter: 'Standard',
        resource: 'partner',
        fields: ["name", "shortname", "street", "zip", "place"],
        filterNames: ['Standard', '', 'Gelöscht'],
        filterQueries: {
            "Standard": { status: { nin: ['deleted'] } },
            'Gelöscht': { status: { eq: 'deleted' } }
        },
        limit: 50
    });

});