define('client/tests/unit/helpers/cor-name-test', ['client/helpers/cor-name', 'qunit'], function (cor_name, qunit) {

  'use strict';

  qunit.module('CorNameHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = cor_name.corName(42);
    assert.ok(result);
  });

});