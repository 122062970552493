define('client/helpers/to-html', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.toHtml = toHtml;

	function toHtml(html /*, hash*/) {
		html = html || "";

		if (html == '0') {
			return '';
		}

		if (/<HEAD>/.test(html) || /<HTML>/.test(html) || /<STYLE>/.test(html)) {

			var removeElements = function removeElements(text, selector) {
				var wrapped = $("<div>" + text + "</div>");
				wrapped.find(selector).remove();
				return wrapped.html();
			};

			html = removeElements(html, "style");
			return html;
		}

		return html.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(toHtml);

});