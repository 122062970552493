define('client/controllers/login', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		actions: {
			login: function login() {

				var self = this;

				var mail = this.get('mail');
				var pass = this.get('pass');
				var data = {
					user: mail,
					password: pass,
					portal: 'salesportal',
					login: true
				};

				this.api.post('login', data).then(function (r) {

					if (r.status === 'success') {

						self.data.set('overlay', 'layouts/login');
						self.data.set('user', r.results);
						self.data.set('online', true);
						//self.io.connect(self, r.results);
						self.transitionToRoute('home');
					} else {
						self.notify.error(r.message);
					}
				})['catch'](function (res) {
					self.notify.error(res.responseJSON.message);
				});
			}
		}
	});

});