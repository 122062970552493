define('client/routes/payoff/payoff', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model-array'], function (exports, Ember, DetailRoute, ModelArray) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Payoff',
		model: function model(params) {
			return this.api.query('extension', { "": { project_id: params.id, type: { nin: ['vdn'], op: 'AND' }, active: { eq: "1", op: 'AND' } }, "AND": { sipSubscribed: { eq: "1", op: "OR" }, ec500Subscribed: { eq: "1", op: "OR" }, messagingSubscribed: { eq: "1", op: "OR" } } }, []).then(function (r) {
				return ModelArray['default'].create().make(r.results);
			});
		},
		afterModel: function afterModel(model) {
			this._super(model);
			var messaging = 0,
			    sip = 0,
			    ec500 = 0;

			Ember['default'].$.each(model, function (k, v) {
				if (v.messagingSubscribed == "1") {
					messaging++;
				}

				if (v.sipSubscribed == "1") {
					sip++;
				}

				if (v.ec500Subscribed == "1") {
					ec500++;
				}
			});

			this.controllerFor('payoff.payoff').set('ec500', ec500);
			this.controllerFor('payoff.payoff').set('messaging', messaging);
			this.controllerFor('payoff.payoff').set('sip', sip);
		}
	});

});