define('client/components/else-cond', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: '',
        isCond: (function () {
            var v1 = this.get('param1');
            var v2 = this.get('param2');
            var operator = this.get('op');
            switch (operator) {
                case "==":
                    return v1 === v2;

                case "!=":
                    return v1 !== v2;

                case "===":
                    return v1 === v2;

                case "!==":
                    return v1 !== v2;

                case "&&":
                    return v1 && v2;

                case "||":
                    return v1 || v2;

                case "<":
                    return v1 < v2;

                case "<=":
                    return v1 <= v2;

                case ">":
                    return v1 > v2;

                case ">=":
                    return v1 >= v2;

                default:
                    return false;
            }
        }).property('param1', 'param2')
    });

});