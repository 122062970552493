define('client/mixins/no-authentication', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		queryParams: {
			refresh: {
				refreshModel: true
			}
		},
		beforeModel: function beforeModel() {
			var user = this.controllerFor('io').get('data.user');

			if (user) {

				this.transitionTo('home');
			}
		},
		actions: {
			willTransition: function willTransition() {
				var user = this.controllerFor('io').get('data.user');

				if (user) {
					this.transitionTo('home');
				}
			}
		}
	});

});