define('client/routes/profile', ['exports', 'ember', 'client/mixins/authentication'], function (exports, Ember, AuthenticationMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticationMixin['default'], {
		titleToken: 'Profile',
		model: function model() {
			return this.data.user;
		}
	});

});