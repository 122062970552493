define('client/tests/unit/helpers/from-now-test', ['client/helpers/from-now', 'qunit'], function (from_now, qunit) {

  'use strict';

  qunit.module('FromNowHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = from_now.fromNow(42);
    assert.ok(result);
  });

});