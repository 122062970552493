define('client/controllers/products', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin', 'client/mixins/model'], function (exports, Ember, SearchFilterController, Model) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: [],
        filter: 'Standard',
        resource: 'product',
        fields: ["name", "category", "description"],
        categories: ['Ports', 'Phones', 'Interface', 'Services', 'Projektleistungen', 'Standortvernetzung', 'Video', 'CallCenter', 'Hotel', 'Retail'],
        orderBy: 'sort',
        products: [],
        orderDirection: 'asc',
        filterNames: ['Aktiv', 'Inaktiv', '', 'Ports', 'Phones', 'Interface', 'Services', 'Projektleistungen', 'Standortvernetzung', 'Video', 'CallCenter', 'Hotel', 'Retail'],
        filterQueries: {
            "Aktiv": { active: 1 },
            'Inaktiv': { active: 0 },
            'Ports': { category: 'Ports' },
            'Phones': { category: 'Phones' },
            'Interface': { category: 'Interface' },
            'Services': { category: 'Services' },
            'Projektleistungen': { category: 'Projektleistungen' },
            'Standortvernetzung': { category: 'Standortvernetzung' },
            'Video': { category: 'Video' },
            'CallCenter': { category: 'CallCenter' },
            'Hotel': { category: 'Hotel' },
            'Retail': { category: 'Retail' }
        },
        limit: 100,
        updateProducts: (function () {
            var model = this.get('model');
            var categories = this.get('categories');
            var output = [];

            Ember['default'].$.each(categories, function (k, category) {

                var obj = { name: category, categories: [] };

                Ember['default'].$.each(model, function (k2, v2) {
                    if (v2.category == category) {

                        if (v2.active) {
                            Ember['default'].set(v2, 'opacity', 'opacity:1');
                        } else {
                            Ember['default'].set(v2, 'opacity', 'opacity:.5');
                        }

                        obj.categories.push(Model['default'].create().make(v2));
                    }
                });

                if (obj.categories.length !== 0) {
                    output.push(obj);
                }
            });

            this.set('products', output);
        }).observes('model.[]', 'model')
    });

});