define('client/routes/partner/partner', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Partner',
		model: function model(params) {
			return this.api.query('partner', { id: params.id }, []).then(function (r) {
				return Model['default'].create().make(r.results[0]);
			});
		},
		afterModel: function afterModel(item) {
			var self = this;

			this.api.query('user', { "company_id": { "eq": item.id, "op": "AND" } }, []).then(function (r) {

				self.controllerFor('partner.partner').set('users', r.results);

				self.cache.setCache('partner', item);
				self._super();
			});
		}
	});

});