define('client/tests/unit/helpers/button-function-name-test', ['client/helpers/button-function-name', 'qunit'], function (button_function_name, qunit) {

  'use strict';

  qunit.module('Unit | Helper | button function name');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = button_function_name.buttonFunctionName(42);
    assert.ok(result);
  });

});