define('client/controllers/application', ['exports', 'ember', 'client/config/environment'], function (exports, Ember, AppConfig) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        config: {
            title: AppConfig['default'].title
        },
        actions: {
            toggleNav: function toggleNav() {
                Ember['default'].$('#sidebar').toggleClass('toggle');
            }
        }
    });

});