define('client/routes/offer', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Offer',
		model: function model(params) {
			return this.api.find('offer', params.id).then(function (r) {
				return Model['default'].create().make(r.results);
			});
		},
		afterModel: function afterModel(model) {

			var self = this;

			self.controllerFor('offer').set('r', Date.now());

			this.api.query('user', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('offer').set('users', r.results);
			});

			if (typeof model.coverletter !== "undefined") {
				if (model.coverletter === "0" || model.coverletter === "") {
					Ember['default'].set(model, 'coverletter', 'ich lade Sie ein, auf den nachfolgenden Seiten mehr über die von uns für Sie entwickelte Lösung zu erfahren, mit der Ihr Unternehmen in die Welt der intelligenten Kommunikation einsteigt. In der Entwicklung Ihrer individuellen Kommunikationslösung berücksichtigen wir verschiedene Aspekte um einen wertvollen Beitrag zur Erfolgssteigerung Ihres Unternehmens zu leisten:<ul><li>Verständnis der Ziele und Anforderungen Ihres Unternehmens<li>Einbettung der Kommunikation in Ihr Business <li>Applikationen, die Menschen zur richtigen Zeit über das geeignete Medium verbinden <li>Programme, die Risiken händelbar machen und Werte erzeugen</li></ul> <br><br>Das Ergebnis ist eine für Sie maßgeschneiderte Kommunikationslösung, die sich stark von anderen Angeboten abhebt. Mit unserem Hostingangebot bieten wir Ihnen unsere sichere, zukunftsorientierte und innovative Kommunikationslösung aus der Cloud an.<br><br>Für Rückfragen stehe ich Ihnen immer gerne zur Verfügung.');
				}
			}

			Ember['default'].$.each(model.positions, function (k, v) {

				Ember['default'].set(v, 'active', parseInt(v.active));

				Ember['default'].set(v, 'payable.rent', parseInt(v.payable.rent));
				Ember['default'].set(v, 'payable.sale', parseInt(v.payable.sale));

				Ember['default'].set(v, 'values_rent_unitprice', parseFloat(v.values_rent_unitprice));
				Ember['default'].set(v, 'values_sale_unitprice', parseFloat(v.values_sale_unitprice));
				Ember['default'].set(v, 'values_setup_unitprice', parseFloat(v.values_setup_unitprice));

				if (v.rent === undefined || v.rent === null) {

					if (v.payment == 'rent') {
						Ember['default'].set(v, 'rent', 1);
					} else if (v.payment == 'sale') {
						Ember['default'].set(v, 'rent', 0);
					} else {
						Ember['default'].set(v, 'rent', parseInt(v.payable.rent));
					}
				} else {
					Ember['default'].set(v, 'rent', parseInt(v.rent));
				}

				if (v.sale === undefined || v.sale === null) {
					if (v.payment == 'rent') {
						Ember['default'].set(v, 'sale', 0);
					} else if (v.payment == 'sale') {
						Ember['default'].set(v, 'sale', 1);
					} else {
						Ember['default'].set(v, 'sale', parseInt(v.payable.sale));
					}
				} else {
					Ember['default'].set(v, 'sale', parseInt(v.sale));
				}

				if (v.category == 'Individuell') {
					Ember['default'].set(v, 'individuell', true);
				} else {
					Ember['default'].set(v, 'individuell', false);
				}

				Ember['default'].set(v, 'rent_dis', parseInt(v.rent) ? 0 : 1);
				Ember['default'].set(v, 'sale_dis', parseInt(v.sale) ? 0 : 1);
			});
		}
	});

});