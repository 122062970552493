define('client/tests/unit/helpers/status-color-test', ['client/helpers/status-color', 'qunit'], function (status_color, qunit) {

  'use strict';

  qunit.module('StatusColorHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = status_color.statusColor(42);
    assert.ok(result);
  });

});