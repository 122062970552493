define('client/tests/unit/mixins/model-test', ['ember', 'client/mixins/model', 'qunit'], function (Ember, ModelMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | model');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var ModelObject = Ember['default'].Object.extend(ModelMixin['default']);
    var subject = ModelObject.create();
    assert.ok(subject);
  });

});