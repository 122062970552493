define('client/router', ['exports', 'ember', 'client/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  exports['default'] = Router.map(function () {
    this.route('login');
    this.route('profile');
    this.route('home');
    this.route('logout');
    this.route('help');
    this.route('password');
    this.route('reset');
    this.route('my-phone');
    this.route('test');
    this.route('loading');
    this.route('error', { path: "*path" });
    this.route('activities', function () {
      this.route('activity', {
        path: ':id'
      });
      this.route('create');
    });
    this.route('contacts', function () {
      this.route('contact', {
        path: ':id'
      }, function () {
        this.route('invoices');
        this.route('offers');
        this.route('orders');
        this.route('activities');
        this.route('projects');
      });
      this.route('create');
    });
    this.route('offers', function () {
      this.route('create');
    });
    this.route('offer', {
      path: '/offers/:id'
    });
    this.route('orders');
    this.route('order', {
      path: '/orders/:id'
    });
    this.route('projects', function () {
      this.route('create');
    });
    this.route('project', {
      path: '/projects/:id'
    });
    this.route('products', function () {
      this.route('product', {
        path: ':id'
      }, function () {
        this.route('extern');
      });
      this.route('create');
    });
    this.route('users', function () {
      this.route('user', {
        path: ':id'
      }, function () {
        this.route('activities');
        this.route('offers');
        this.route('orders');
        this.route('invoices');
        this.route('projects');
      });
      this.route('create');
    });
    this.route('partner', function () {
      this.route('partner', {
        path: ':id'
      });
      this.route('create');
    });
    this.route('marketing');
    this.route('payoff', function () {
      this.route('payoff', {
        path: ':id'
      });
    });
  });

});