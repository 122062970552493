define('client/mixins/model-array', ['exports', 'ember', 'client/mixins/model'], function (exports, Ember, Model) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		make: function make(arr) {
			var response = [];
			Ember['default'].$.each(arr, function (k, v) {
				response.push(Model['default'].create().make(v));
			});
			return response;
		}
	});

});