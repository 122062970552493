define('client/tests/unit/helpers/to-html-test', ['client/helpers/to-html', 'qunit'], function (to_html, qunit) {

  'use strict';

  qunit.module('Unit | Helper | to html');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = to_html.toHtml(42);
    assert.ok(result);
  });

});