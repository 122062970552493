define('client/helpers/only-date', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.onlyDate = onlyDate;

	function onlyDate(date /*, hash*/) {
		if (moment(date).isValid()) {
			moment.locale('de');
			return moment(date).format('L');
		}

		return '-';
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(onlyDate);

});