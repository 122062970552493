define('client/controllers/offers/create', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['offers'],
		resource: 'offer',
		root: 'offers',
		durations: [{ id: '0', name: 'Ohne Laufzeit' }, { id: '12', name: '12 Monate' }, { id: '24', name: '24 Monate' }, { id: '36', name: '36 Monate' }, { id: '48', name: '48 Monate' }, { id: '60', name: '60 Monate' }],
		updateContact: (function () {
			var contact = this.get('contact');
			this.set('model.recipient', contact.id);
		}).observes('contact.id'),
		createItem: function createItem() {
			var self = this;
			var model = this.get('model');

			this.api.create(this.get('resource'), { data: JSON.stringify(model) }).then(function (r) {
				self.notify.info(r.message);
				self.set('model', {});
				self.transitionToRoute('offer', r.results);
			})['catch'](function (r) {
				self.notify.error(r.responseJSON.message);
			});
		},
		actions: {
			queryContacts: function queryContacts(query, deferred) {
				this.api.query('contact', { firma: { like: query.term, op: "OR" }, name: { like: query.term, op: "OR" } }, [], 1, 10000).then(function (data) {
					deferred.resolve({ data: data.results, more: false });
				}, deferred.reject);
			}
		}
	});

});