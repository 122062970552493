define('client/tests/unit/helpers/only-date-test', ['client/helpers/only-date', 'qunit'], function (only_date, qunit) {

  'use strict';

  qunit.module('Unit | Helper | only date');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = only_date.onlyDate(42);
    assert.ok(result);
  });

});