define('client/routes/products/product', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Product',
		model: function model(params) {
			return this.api.query('product', { id: params.id }, []).then(function (r) {
				return Model['default'].create().make(r.results[0]);
			});
		},
		afterModel: function afterModel(item) {
			var self = this;
			self.cache.setCache('product', item);
			self._super();
		}
	});

});