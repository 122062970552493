define('client/controllers/payoff', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin'], function (exports, Ember, SearchFilterController) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ["contacts", "users"],
        filter: '',
        resource: 'project',
        fields: ["name", "contacts.name", "contacts.firma"],
        filterNames: [],
        filterQueries: {},
        limit: 50
    });

});