define('client/controllers/offers', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin'], function (exports, Ember, SearchFilterController) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ["user", "contacts", "activity"],
        filter: 'Standard',
        resource: 'offer',
        fields: ["contacts.firma", "contact.name", "user.name", "user.mail", "opportunity", "number"],
        filterNames: ['Standard', '', 'Meine', 'Verloren', '', 'Gelöscht'],
        filterQueries: (function () {
            return {
                'Standard': { "": { status: { nin: ['deleted', 'lost', 'order'], "op": "AND" } } },
                'Meine': { "": { status: { "nin": ['deleted', 'lost', 'order'], "op": "AND" }, seller: { "eq": this.data.user.id, "op": "and" } } },
                'Verloren': { "": { status: { 'in': ["lost"], "op": "AND" } } },
                'Gelöscht': { "": { status: { 'in': ["deleted"], "op": "AND" } } }
            };
        }).property(),
        limit: 50,
        totals_month: (function () {
            var model = this.get('model');
            var net = 0;
            Ember['default'].$.each(model, function (k, v) {
                net = net + parseFloat(v.totals_monthly_net);
            });

            return net;
        }).property('model.@each'),
        totals_buy: (function () {
            var model = this.get('model');
            var net = 0;
            Ember['default'].$.each(model, function (k, v) {
                net = net + parseFloat(v.totals_buy_net);
            });

            return net;
        }).property('model.@each'),
        totals_setup: (function () {
            var model = this.get('model');
            var net = 0;
            Ember['default'].$.each(model, function (k, v) {
                net = net + parseFloat(v.totals_setup_net);
            });

            return net;
        }).property('model.@each')
    });

});