define('client/services/api', ['exports', 'ember', 'client/config/environment'], function (exports, Ember, config) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({

		config: config['default'],

		find: function find(table, id, query) {
			query = query || {};
			if (id === undefined || !id) {
				id = '';
			} else {
				id = '/' + id;
			}

			return this.ajax('GET', this.config.apiUrl + table + id, {});
		},

		findAll: function findAll(table, query) {

			query = query || {};

			return this.ajax('GET', this.config.apiUrl + table, query);
		},

		save: function save(table, id, data) {
			data = data || {};
			if (id === undefined || !id) {
				id = '';
			} else {
				id = '/' + id;
			}

			return this.ajax('PUT', this.config.apiUrl + table + id, data);
		},

		create: function create(table, data) {
			data = data || {};

			return this.ajax('POST', this.config.apiUrl + table, data);
		},

		post: function post(table, data) {
			data = data || {};

			return this.ajax('POST', this.config.apiUrl + table, data);
		},

		remove: function remove(table, id) {

			if (id === undefined || !id) {
				id = '';
			} else {
				id = '/' + id;
			}

			return this.ajax('DELETE', this.config.apiUrl + table + id, {});
		},

		'delete': function _delete(table, id) {
			return this.remove(table, id);
		},

		query: function query(table, _query, includes, page, limit, orderBy, direction, additional) {
			return this.getQuery('query', table, _query, includes, page, limit, orderBy, direction, additional);
		},

		count: function count(table, query, includes, page, limit, orderBy, direction, additional) {
			return this.getQuery('count', table, query, includes, page, limit, orderBy, direction, additional);
		},

		getQuery: function getQuery(urlBag, table, query, includes, page, limit, orderBy, direction, additional) {

			urlBag = urlBag || 'query';
			query = query || {};
			includes = includes || [];
			page = page || 1;
			limit = limit || 50;
			orderBy = orderBy || 'id';
			direction = direction || 'desc';
			additional = additional || '';

			var t = this;
			var url = this.config.apiUrl + table + '/' + urlBag;
			url = url + '?query=' + t.toStr(query);
			url = url + '&include=' + t.toStr(includes);
			url = url + '&page=' + page;
			url = url + '&limit=' + limit;
			url = url + '&orderBy=' + orderBy;
			url = url + '&orderDirection=' + direction;
			url = url + additional;

			return this.ajax('GET', url, {});
		},

		ajax: function ajax(type, url, query) {

			var self = this;

			var options = {
				type: type,
				data: query,
				async: !this.config.sync
			};

			if (this.config.withCredentials) {
				options.xhrFields = {
					withCredentials: true
				};
			}

			self.log('URL: ' + url, query);

			return new Ember['default'].RSVP.Promise(function (resolve, reject) {
				Ember['default'].$.ajax(url, options).then(function (data) {
					self.log(data, '');
					Ember['default'].run(null, resolve, data);
				}, function (jqXHR) {

					if (jqXHR.status === 401 && self.config.allowedURLs.indexOf(window.location.pathname) === -1) {
						var JQ = Ember['default'].$;
						var relogin = JQ('<div>').addClass('relogin').html('<div class="box"><h2>Session abgelaufen</h2><a href="/login">Bitte loggen Sie sich erneut ein!</a></div>');
						//window.Client.__container__.lookup('route:reset').transitionTo('login');
						window.redirect = window.location.pathname;
						JQ('body').append(relogin);
					}

					var error = typeof jqXHR.responseJSON === 'object' ? jqXHR.responseJSON : {};
					error.raw = jqXHR.responseText;
					error.status = jqXHR.status;
					error.message = error.message || '';
					error.responseJSON = typeof jqXHR.responseJSON === 'object' ? jqXHR.responseJSON : {};

					console.error(error);

					jqXHR.then = null; // tame jQuery's ill mannered promises
					Ember['default'].run(null, reject, error);
				});
			});
		},

		toStr: function toStr(str) {
			return JSON.stringify(str);
		},

		log: function log() {
			if (this.config.environment === 'development') {

				for (var i = 0; i < arguments.length; i++) {
					console.log(arguments[i]);
				}
			}
		}

	});

});