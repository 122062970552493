define('client/components/list-picker', ['exports', 'ember', 'ember-cli-select-picker/mixins/select-picker'], function (exports, Ember, SelectPickerMixin) {

  'use strict';

  var I18nProps = Ember['default'].I18n && Ember['default'].I18n.TranslateableProperties || {};

  var ListPickerComponent = Ember['default'].Component.extend(SelectPickerMixin['default'], I18nProps, {

    selectAllLabel: 'Select All',
    selectNoneLabel: 'Select None',

    nativeMobile: false,

    classNames: ['select-picker', 'list-picker'],

    groupedContentList: Ember['default'].computed('contentList.@each', function () {
      var groups = Ember['default'].A();
      var content = Ember['default'].A();
      this.get('contentList').forEach(function (item) {
        var header,
            itemGroup = item.get('group');
        var groupIndex = groups.indexOf(itemGroup);
        if (groupIndex < 0) {
          header = itemGroup;
          groups.push(header);
          content.push(Ember['default'].Object.create({
            header: header,
            items: Ember['default'].A([item])
          }));
        } else {
          content[groupIndex].get('items').push(item);
        }
      });
      return content;
    })
  });

  exports['default'] = ListPickerComponent;

});