define('client/helpers/status-label', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.statusLabel = statusLabel;

	function statusLabel(status /*, hash*/) {
		var res;
		switch (status) {
			case "opportunity":
				res = 'primary';
				break;
			case "customer":
				res = 'default';
				break;
			case "declined":
				res = 'danger';
				break;
			case "deleted":
				res = 'danger';
				break;
			case "accepted":
				res = 'success';
				break;
			case "assigned":
				res = 'info';
				break;
			case "order":
				res = 'default';
				break;
			case "lost":
				res = 'danger';
				break;
			case "created":
				res = 'success';
				break;
			case "draft":
				res = 'default';
				break;
			case "open":
				res = 'warning';
				break;
			case "open":
				res = 'danger';
				break;
			case "paid":
				res = 'success';
				break;
			case "0":
				res = 'default';
				break;
			case "1":
				res = 'success';
				break;
			default:
				res = 'default';
				break;

		}

		return new Ember['default'].Handlebars.SafeString(res);
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(statusLabel);

});