define('client/controllers/users/user/index', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['users'],
		root: 'users',
		queryParams: ['edit'],
		resource: 'user',
		removeItem: function removeItem() {
			this._super();
			this.get('controllers.users').set('refresh', Date.now());
		},
		actions: {
			activate: function activate() {
				var model = this.get('model');
				var self = this;

				this.api.save("user/" + model.id + "/active", false, { active: 1 }).then(function (r) {
					self.set('model.active', 1);
					self.notify.info(r.message);
				});
			},
			deactivate: function deactivate() {
				var model = this.get('model');
				var self = this;

				this.api.save("user/" + model.id + "/active", false, { active: 0 }).then(function (r) {
					self.set('model.active', 0);
					self.notify.info(r.message);
				});
			},
			pass: function pass() {
				var model = this.get('model');
				var self = this;

				this.api.save("user/" + model.id + "/passLeadsToUser/" + this.get('passto').id).then(function (r) {
					self.set('passto', null);
					self.notify.info(r.message);
				});
			},
			queryCompanies: function queryCompanies(query, deferred) {
				this.api.query('partner', { name: { like: query.term, op: "OR" }, shortname: { like: query.term, op: "OR" } }, [], 1, 10000).then(function (data) {
					deferred.resolve({ data: data.results, more: false });
				}, deferred.reject);
			},
			queryUsers: function queryUsers(query, deferred) {
				this.api.query('user', { name: { like: query.term, op: "OR" }, mail: { like: query.term, op: "OR" } }, [], 1, 10000).then(function (data) {
					deferred.resolve({ data: data.results, more: false });
				}, deferred.reject);
			}
		}

	});

});