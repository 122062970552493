define('client/templates/components/file-drop', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createElement("small");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","dropzone");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2,"type","file");
        dom.setAttribute(el2,"name","file");
        dom.setAttribute(el2,"id","file_upload");
        dom.setAttribute(el2,"style","display:none;");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","cursor");
        var el3 = dom.createElement("small");
        var el4 = dom.createTextNode("Entfernen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var element3 = dom.childAt(element2, [1]);
        var attrMorph0 = dom.createAttrMorph(element0, 'class');
        var morph0 = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
        var attrMorph1 = dom.createAttrMorph(element1, 'style');
        var attrMorph2 = dom.createAttrMorph(element2, 'class');
        attribute(env, attrMorph0, element0, "class", concat(env, [subexpr(env, context, "if", [get(env, context, "center"), "text-center"], {})]));
        content(env, morph0, context, "label");
        attribute(env, attrMorph1, element1, "style", concat(env, ["width: ", get(env, context, "width"), "; height: ", get(env, context, "height"), "; border: 1px solid #ddd; ", subexpr(env, context, "if", [get(env, context, "center"), "margin: 0 auto;", ""], {}), " cursor:pointer;background-image: url('http://assets.comulus.net/", get(env, context, "dir"), "/", subexpr(env, context, "if", [get(env, context, "image"), get(env, context, "image"), get(env, context, "placeholder")], {}), "'); background-size: contain; background-position: center center; background-repeat: no-repeat;"]));
        attribute(env, attrMorph2, element2, "class", concat(env, [subexpr(env, context, "if", [get(env, context, "center"), "text-center"], {})]));
        element(env, element3, context, "action", ["remove"], {});
        return fragment;
      }
    };
  }()));

});