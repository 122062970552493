define('client/tests/unit/helpers/status-name-test', ['client/helpers/status-name', 'qunit'], function (status_name, qunit) {

  'use strict';

  qunit.module('StatusNameHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = status_name.statusName(42);
    assert.ok(result);
  });

});