define('client/tests/unit/helpers/badge-icon-test', ['client/helpers/badge-icon', 'qunit'], function (badge_icon, qunit) {

  'use strict';

  qunit.module('Unit | Helper | badge icon');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = badge_icon.badgeIcon(42);
    assert.ok(result);
  });

});