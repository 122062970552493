define('client/tests/unit/mixins/authentication-test', ['ember', 'client/mixins/authentication', 'qunit'], function (Ember, AuthenticationMixin, qunit) {

  'use strict';

  qunit.module('AuthenticationMixin');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var AuthenticationObject = Ember['default'].Object.extend(AuthenticationMixin['default']);
    var subject = AuthenticationObject.create();
    assert.ok(subject);
  });

});