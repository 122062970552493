define('client/routes/orders', ['exports', 'ember', 'client/mixins/authentication', 'client/mixins/model-array'], function (exports, Ember, AuthenticationMixin, ModelArray) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticationMixin['default'], {
        titleToken: 'Orders',
        model: function model(params) {
            var self = this;
            return this.api.query('order', this.controllerFor('orders').buildQuery(params), ["contacts", "user"], 1, this.controllerFor('orders').get('limit') * params.page).then(function (r) {
                self.controllerFor('orders').set('count', r.count);
                return ModelArray['default'].create().make(r.results);
            });
        },
        afterModel: function afterModel() {
            var self = this;
            this.api.query('order', { status: { nin: ['lost', 'deleted'] } }, [], 1, 10000).then(function (r) {

                var totals = {
                    month: 0,
                    buy: 0,
                    setup: 0
                };

                Ember['default'].$.each(r.results, function (k, v) {
                    totals.month += parseFloat(v.totals_monthly_net);
                    totals.buy += parseFloat(v.totals_buy_net);
                    totals.setup += parseFloat(v.totals_setup_net);
                });

                self.controllerFor('orders').set('totals', totals);
            });
        },
        resetController: function resetController(controller, isExiting /*, transition*/) {
            if (isExiting) {
                controller.set('page', 1);
            }
        }
    });

});