define('client/initializers/ember-notify', ['exports', 'ember-notify'], function (exports, Notify) {

  'use strict';

  exports['default'] = {
    name: 'ember-notify',
    initialize: function initialize(container, app) {
      container.optionsForType('notify', { instantiate: false, singleton: true });
      app.register('notify:main', Notify['default']);
      app.inject('route', 'notify', 'notify:main');
      app.inject('controller', 'notify', 'notify:main');
      app.inject('component', 'notify', 'notify:main');
    }
  };

});