define('client/controllers/users', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin'], function (exports, Ember, SearchFilterController) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ["activity", "projects", "orders", "offers", "invoices", "skills", "company"],
        filter: 'Standard',
        resource: 'user',
        //preFilter: {active: {eq:1, op:"AND"}},
        fields: ["mail", "name", "telefon"],
        filterNames: ['Aktiv', 'Inaktiv', '', 'Sales Portal', 'Customer Portal', 'Beides'],
        filterQueries: (function () {
            return {
                'Aktiv': { active: { eq: 1 } },
                'Inaktiv': { active: { eq: 0 } },
                'Sales Portal': { salesportal: { eq: 1 } },
                'Customer Portal': { customerportal: { eq: 1 } },
                'My Portal': { myportal: { eq: 1 } },
                'Beides': { "": { salesportal: { eq: 1, op: "AND" }, customerportal: { eq: 1, op: "AND" } } }
            };
        }).property(),
        limit: 50
    });

});