define('client/helpers/badge-icon', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.badgeIcon = badgeIcon;

	function badgeIcon(length) {

		if (length) {
			return '<i class="badge">' + length + '</i>';
		} else {
			return '';
		}
	}

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(badgeIcon);

});