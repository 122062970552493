define('client/routes/activities/create', ['exports', 'ember', 'client/mixins/detail-route-mixin'], function (exports, Ember, DetailRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		model: function model() {
			return { activitytype: 'todo' };
		},
		afterModel: function afterModel() {
			var self = this;
			this.api.query('project', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('activities/create').set('projects', r.results);
			});
		}
	});

});