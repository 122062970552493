define('client/mixins/detail-route-mixin', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		queryParams: {
			refresh: {
				refreshModel: true
			}
		},
		resetController: function resetController(controller /*, isExiting, transition*/) {
			controller.set('data.detail', false);
		},
		setupController: function setupController(controller, model) {
			// Call _super for default behavior
			this._super(controller, model);
			// Implement your custom setup after
			controller.set('data.detail', true);
		},
		afterModel: function afterModel() /*model, transition*/{
			this.controllerFor('contacts').set('data.detail', true);
			this._super();
		},
		beforeModel: function beforeModel() {
			var user = this.controllerFor('io').get('data.user');

			if (!user) {
				this.transitionTo('index');
			}
		},
		actions: {
			willTransition: function willTransition() {
				var user = this.controllerFor('io').get('data.user');

				if (!user) {
					this.transitionTo('index');
				}
			}
		}
	});

});