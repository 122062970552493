define('client/tests/unit/mixins/no-authentication-test', ['ember', 'client/mixins/no-authentication', 'qunit'], function (Ember, NoAuthenticationMixin, qunit) {

  'use strict';

  qunit.module('NoAuthenticationMixin');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var NoAuthenticationObject = Ember['default'].Object.extend(NoAuthenticationMixin['default']);
    var subject = NoAuthenticationObject.create();
    assert.ok(subject);
  });

});