define('client/components/keyboard-select-picker', ['exports', 'ember', 'client/components/select-picker', 'ember-keyboard-shortcuts/mixins/component'], function (exports, Ember, SelectPicker, KeyboardShortcutsMixin) {

  'use strict';

  function makeKeyboardAction(fn) {
    return function () {
      if (!this.get('showDropdown')) {
        // ignore keyboard input on components that are not *in focus*
        return true;
      }
      fn.apply(this, arguments);
      return false;
    };
  }

  var KeyboardSelectPickerComponent = SelectPicker['default'].extend(KeyboardShortcutsMixin['default'], {

    layoutName: 'components/select-picker',

    nativeMobile: true,

    activeCursor: null,

    classNames: ['select-picker', 'keyboard-select-picker'],

    groupedContentList: Ember['default'].computed('groupedContentListWithoutActive', 'activeIndex', function () {
      var activeIndex = this.get('activeIndex');
      var result = Ember['default'].A(this.get('groupedContentListWithoutActive'));
      result.forEach(function (item, index) {
        item.set('active', index === activeIndex);
      });
      return result;
    }),

    activeIndex: Ember['default'].computed('activeCursor', 'contentList.length', function () {
      var cursor = this.get('activeCursor');
      if (Ember['default'].isNone(cursor)) {
        return null;
      }
      var len = this.get('contentList.length');
      return (cursor % len + len) % len;
    }),

    activeItem: Ember['default'].computed('activeIndex', 'contentList.[]', function () {
      return this.get('contentList').objectAt(this.get('activeIndex'));
    }),

    keyboardShortcuts: {
      'enter': 'selectActiveItem',
      'up': 'activePrev',
      'down': 'activeNext',
      'shift+tab': 'activePrev',
      'tab': 'activeNext',
      'esc': 'closeDropdown'
    },

    actions: {
      activeNext: makeKeyboardAction(function () {
        if (Ember['default'].isNone(this.get('activeCursor'))) {
          this.set('activeCursor', 0);
        } else {
          this.incrementProperty('activeCursor');
        }
      }),

      activePrev: makeKeyboardAction(function () {
        if (Ember['default'].isNone(this.get('activeCursor'))) {
          this.set('activeCursor', -1);
        } else {
          this.decrementProperty('activeCursor');
        }
      }),

      selectActiveItem: makeKeyboardAction(function () {
        var item = this.get('activeItem');
        if (Ember['default'].isPresent(item)) {
          this.send('selectItem', item);
        }
      })
    }
  });

  exports['default'] = KeyboardSelectPickerComponent;

});