define('client/tests/unit/helpers/status-label-test', ['client/helpers/status-label', 'qunit'], function (status_label, qunit) {

  'use strict';

  qunit.module('StatusLabelHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = status_label.statusLabel(42);
    assert.ok(result);
  });

});