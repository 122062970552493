define('client/tests/unit/helpers/translate-test', ['client/helpers/translate', 'qunit'], function (translate, qunit) {

  'use strict';

  qunit.module('TranslateHelper');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = translate.translate(42);
    assert.ok(result);
  });

});