define('client/controllers/orders', ['exports', 'ember', 'client/mixins/search-filter-controller-mixin'], function (exports, Ember, SearchFilterController) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(SearchFilterController['default'], {
        includes: ["user", "contacts", "activity"],
        filter: 'Standard',
        resource: 'order',
        fields: ["contacts.firma", "contact.name", "user.name", "user.mail", "opportunity"],
        filterNames: ['Standard', '', 'Meine'],
        filterQueries: (function () {
            return {
                'Standard': {},
                'Meine': { "": { seller: { "eq": this.data.user.id, "op": "and" } } }
            };
        }).property(),
        limit: 50,
        totals_month: (function () {
            var model = this.get('model');
            var net = 0;
            Ember['default'].$.each(model, function (k, v) {
                net = net + parseFloat(v.totals_monthly_net);
            });

            return net;
        }).property('model.@each'),
        totals_buy: (function () {
            var model = this.get('model');
            var net = 0;
            Ember['default'].$.each(model, function (k, v) {
                net = net + parseFloat(v.totals_buy_net);
            });

            return net;
        }).property('model.@each'),
        totals_setup: (function () {
            var model = this.get('model');
            var net = 0;
            Ember['default'].$.each(model, function (k, v) {
                net = net + parseFloat(v.totals_setup_net);
            });

            return net;
        }).property('model.@each')
    });

});