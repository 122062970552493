define('client/routes/contacts', ['exports', 'ember', 'client/mixins/authentication', 'client/mixins/model-array'], function (exports, Ember, AuthenticationMixin, ModelArray) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticationMixin['default'], {
        titleToken: 'Contacts',
        model: function model(params) {
            var self = this;
            return this.api.query('contact', this.controllerFor('contacts').buildQuery(params), ["offers", "orders", "user", "projects", "invoices", "activity"], 1, this.controllerFor('contacts').get('limit') * params.page).then(function (r) {
                self.controllerFor('contacts').set('count', r.count);
                return ModelArray['default'].create().make(r.results);
            });
        },
        afterModel: function afterModel() {
            var self = this;

            this.api.query('user', {}, [], 1, 100000).then(function (r) {
                self.data.set('users', r.results);
            });
        },
        resetController: function resetController(controller, isExiting /*, transition*/) {
            if (isExiting) {
                controller.set('page', 1);
            }
        }
    });

});