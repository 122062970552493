define('client/routes/logout', ['exports', 'ember', 'client/mixins/authentication'], function (exports, Ember, AuthenticationMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticationMixin['default'], {
		title: 'Logout',
		logout: function logout() {

			var self = this;

			this.api.find('logout').then(function (r) {
				if (r.status === 'success') {

					self.data.set('overlay', 'layouts/main');
					self.data.set('user', false);
					self.data.set('online', false);
					//self.io.disconnect();
					self.transitionTo('index');
				} else {
					self.notify.error(r.message);
				}
			});
		},
		beforeModel: function beforeModel() {

			this._super();

			this.logout();
		},
		actions: {
			willTransition: function willTransition() {
				this._super();
				this.logout();
			}
		}
	});

});