define('client/mixins/detail-controller-mixin', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		edit: false,
		saveItem: function saveItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.get('model');
			var compared = this.cache.compare(this.get('resource'), model);

			this.api.save(this.get('resource'), model.id, { data: JSON.stringify(compared) }).then(function (r) {
				self.notify.info(r.message);
				self.set('edit', false);
				if (typeof callback === "function") {
					callback(r);
				}
				self.cache.setCache(self.get('resource'), model);
			})['catch'](function (r) {
				self.notify.error(r.responseJSON.message);
			});
		},

		removeItem: function removeItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.get('model');

			this.api.remove(this.get('resource'), model.id).then(function (r) {
				self.set('remove', false);
				self.notify.info(r.message);
				if (typeof callback === "function") {
					callback(r);
				}
				self.transitionToRoute(self.root);
			})['catch'](function (r) {
				self.notify.error(r.responseJSON.message);
			});
		},

		createItem: function createItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.get('model');

			this.api.create(this.get('resource'), { data: JSON.stringify(model) }).then(function (r) {
				self.notify.info(r.message);
				self.set('model', {});
				if (typeof callback === "function") {
					callback(r);
				}
				self.transitionToRoute(self.root);
			})['catch'](function (r) {
				self.notify.error(r.responseJSON.message);
			});
		},
		actions: {

			save: function save() {
				this.saveItem();
			},

			remove: function remove() {
				this.removeItem();
			},

			create: function create() {
				this.createItem();
			},

			toggleRemove: function toggleRemove() {
				this.set('remove', !this.get('remove'));
			},

			toggleEdit: function toggleEdit() {
				if (this.get('edit') === true) {
					if (typeof this.get('model').rollback !== 'undefined') {
						//this.get('model').rollback();
					}
				}
				this.set('edit', !this.get('edit'));
			},

			copy: function copy() {
				// save
				// this.get('model')
				this.notify.info('Demo: Copied');
			}
		}
	});

});