define('client/controllers/activities/activity', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		root: 'activities',
		resource: 'activity',
		checkType: function checkType(type) {
			if (this.get('model.activitytype') == type) {
				return true;
			}
			return false;
		},
		isTodo: (function () {
			return this.checkType('todo');
		}).property('model.activitytype'),
		isWork: (function () {
			return this.checkType('work');
		}).property('model.activitytype'),
		isLog: (function () {
			return this.checkType('log');
		}).property('model.activitytype'),
		isCallcenter: (function () {
			return this.checkType('callcenter');
		}).property('model.activitytype'),
		isComment: (function () {
			return this.checkType('comment');
		}).property('model.activitytype'),
		isTicket: (function () {
			return this.checkType('ticket');
		}).property('model.activitytype')
	});

});