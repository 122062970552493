define('client/components/field-editor', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        _editor: null,
        value: null,
        'on-change': null,

        didInsertElement: function didInsertElement() {
            var _this = this;

            var textarea = this.element.querySelector('.editor');
            var editor = this._editor = CKEDITOR.replace(textarea, {
                format_tags: 'p;h1;h2;h3;pre',
                uiColor: '#ffffff',
                font_defaultLabel: 'Arial',
                fontSize_defaultLabel: '12px'
            });
            editor.on('change', function (e) {
                console.log(e.editor.getData());
                _this.set('value', e.editor.getData());
                _this.sendAction('on-change', e.editor.getData());
            });
        },

        willDestroyElement: function willDestroyElement() {
            this._editor.destroy();
            this._editor = null;
        }
    });

});