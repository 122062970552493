define('client/helpers/translate', ['exports'], function (exports) {

  'use strict';

  //import Ember from 'ember';

  exports['default'] = function (params) {
    return params;
  }

});