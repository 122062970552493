define('client/views/offer-rated', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({
		templateName: 'views/offer-rated',
		sum: (function () {
			var item = this.get('offer');
			return item.totals_monthly_net * item.duration * item.opportunity * 0.01 + item.totals_buy_net * item.opportunity * 0.01 + item.totals_setup_net * item.opportunity * 0.01;
		}).property('offer'),
		monthly: (function () {
			var item = this.get('offer');
			return item.totals_monthly_net * item.duration * item.opportunity * 0.01;
		}).property('offer'),
		buy: (function () {
			var item = this.get('offer');
			return item.totals_buy_net * item.opportunity * 0.01;
		}).property('offer'),
		setup: (function () {
			var item = this.get('offer');
			return item.totals_setup_net * item.opportunity * 0.01;
		}).property('offer')
	});

});