define('client/tests/unit/mixins/detail-route-mixin-test', ['ember', 'client/mixins/detail-route-mixin', 'qunit'], function (Ember, DetailRouteMixinMixin, qunit) {

  'use strict';

  qunit.module('DetailRouteMixinMixin');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var DetailRouteMixinObject = Ember['default'].Object.extend(DetailRouteMixinMixin['default']);
    var subject = DetailRouteMixinObject.create();
    assert.ok(subject);
  });

});