define('client/routes/users/create', ['exports', 'ember', 'client/mixins/detail-route-mixin'], function (exports, Ember, DetailRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		model: function model() {
			return {};
		},
		afterModel: function afterModel() {

			var self = this;

			this.api.query('partner', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('users.create').set('partner', r.results);
			});

			this._super();
		}
	});

});