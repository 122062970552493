define('client/routes/products', ['exports', 'ember', 'client/mixins/authentication', 'client/mixins/model-array'], function (exports, Ember, AuthenticationMixin, ModelArray) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticationMixin['default'], {
        titleToken: 'Products',
        model: function model(params) {
            var self = this;
            return this.api.query('product', this.controllerFor('products').buildQuery(params), [], 1, this.controllerFor('products').get('limit') * params.page, 'sort', 'asc').then(function (r) {
                self.controllerFor('products').set('count', r.count);
                return ModelArray['default'].create().make(r.results);
            });
        },
        resetController: function resetController(controller, isExiting /*, transition*/) {
            if (isExiting) {
                controller.set('page', 1);
            }
        }
    });

});