define('client/controllers/products/product/index', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['products'],
		root: 'product',
		queryParams: ['edit'],
		resource: 'product',
		categories: ['Ports', 'Phones', 'Interface', 'Services', 'Projektleistungen', 'Standortvernetzung', 'Video', 'CallCenter', 'Hotel', 'Retail'],
		num: function num(str) {
			var reg = /[,]/;
			if (reg.test(str) === true) {
				return str;
			}
			return parseFloat(str).formatNumber(2, '.', ',');
		},
		removeItem: function removeItem() {
			this._super();
			this.get('controllers.products').set('refresh', Date.now());
		},
		rent: (function () {
			var item = this.get('model');

			if (item.values_rent_default !== "0" && item.values_rent_min !== "0") {
				return "<i title='Einkaufspreis' data-toggle='tooltip' class='fa fa-sign-in'></i> <span class='number'>" + this.num(item.values_rent_min) + "</span> €<br><i title='empholener Verkaufspreis' data-toggle='tooltip' class='fa fa-sign-out'></i> <span class='number'>" + this.num(item.values_rent_default) + "</span> €";
			}

			return "keine Miete möglich";
		}).property('model.values_rent_default', 'model.values_rent_min'),
		sale: (function () {
			var item = this.get('model');

			if (item.values_sale_default !== "0" && item.values_sale_min !== "0") {
				return "<i title='Einkaufspreis' data-toggle='tooltip' class='fa fa-sign-in'></i> <span class='number'>" + this.num(item.values_sale_min) + "</span> €<br><i title='empholener Verkaufspreis' data-toggle='tooltip' class='fa fa-sign-out'></i> <span class='number'>" + this.num(item.values_sale_default) + "</span> €";
			}

			return "kein Kauf möglich";
		}).property('model.values_sale_default', 'model.values_sale_min'),
		setup: (function () {
			var item = this.get('model');

			if (item.values_setup_default !== "0" && item.values_setup_min !== "0") {
				return "<i title='Einkaufspreis' data-toggle='tooltip' class='fa fa-sign-in'></i> <span class='number'>" + this.num(item.values_setup_min) + "</span> €<br><i title='empholener Verkaufspreis' data-toggle='tooltip' class='fa fa-sign-out'></i> <span class='number'>" + this.num(item.values_setup_default) + "</span> €";
			}

			return "keine Setupgebühr";
		}).property('model.values_setup_default', 'model.values_setup_min'),
		payable_sale: (function () {
			var model = this.get('model');
			console.log(model.payable_sale);
			if (model.payable_sale === "1") {
				return true;
			}
			return false;
		}).property('model.payable_sale'),
		payable_rent: (function () {
			var model = this.get('model');
			if (model.payable_rent === "1") {
				return true;
			}
			return false;
		}).property('model.payable_rent')

	});

});