define('client/routes/order', ['exports', 'ember', 'client/mixins/detail-route-mixin', 'client/mixins/model'], function (exports, Ember, DetailRoute, Model) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Offer',
		model: function model(params) {
			return this.api.find('order', params.id).then(function (r) {
				return Model['default'].create().make(r.results);
			});
		},
		afterModel: function afterModel(model) {

			Ember['default'].$.each(model.positions, function (k, v) {

				Ember['default'].set(v, 'active', parseInt(v.active));

				Ember['default'].set(v, 'values_rent_unitprice', parseFloat(v.values_rent_unitprice));
				Ember['default'].set(v, 'values_sale_unitprice', parseFloat(v.values_sale_unitprice));
				Ember['default'].set(v, 'values_setup_unitprice', parseFloat(v.values_setup_unitprice));

				if (v.rent === undefined || v.rent === null) {

					if (v.payment == 'rent') {
						Ember['default'].set(v, 'rent', 1);
					} else if (v.payment == 'sale') {
						Ember['default'].set(v, 'rent', 0);
					}
				} else {
					Ember['default'].set(v, 'rent', parseInt(v.rent));
				}

				if (v.sale === undefined || v.sale === null) {
					if (v.payment == 'rent') {
						Ember['default'].set(v, 'sale', 0);
					} else if (v.payment == 'sale') {
						Ember['default'].set(v, 'sale', 1);
					}
				} else {
					Ember['default'].set(v, 'sale', parseInt(v.sale));
				}
			});
		}
	});

});