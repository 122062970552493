define('client/controllers/contacts/create', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['contacts'],
		resource: 'contact',
		root: 'contacts',
		createItem: function createItem() {
			this._super();
			this.get('controllers.contacts').set('refresh', Date.now());
		}
	});

});