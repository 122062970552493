define('client/components/process-bar', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		now: 0,
		min: 0,
		max: 100,
		style: ''
	});

});