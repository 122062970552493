define('client/controllers/home', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		extensions: ' ',
		pickupgroups: ' ',
		locations: ' ',
		reports: {
			all: {},
			today: {},
			week: {},
			month: {}
		}
	});

});