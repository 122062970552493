define('client/controllers/order', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		root: 'offers',
		resource: 'offer',
		queryParams: ['edit'],
		categories: ['Ports', 'Phones', 'Interface', 'Services', 'Projektleistungen', 'Standortvernetzung', 'Video', 'CallCenter', 'Hotel', 'Retail', 'Individuell'],
		parsePositions: function parsePositions() {
			var model = this.get('model');
			var categories = this.get('categories');
			var output = [];

			Ember['default'].$.each(categories, function (k, category) {

				var obj = { name: category, active: 0, rent: 0, sale: 0, setup: 0, positions: [], collapse: '' };
				Ember['default'].$.each(model.positions, function (k2, v2) {
					if (v2.category == category) {

						if (v2.active) {
							Ember['default'].set(v2, 'opacity', 'opacity:1');
						} else {
							Ember['default'].set(v2, 'opacity', 'opacity:.3');
						}

						Ember['default'].set(v2, 'showDescription', false);

						obj.positions.push(v2);
					}
				});
				output.push(obj);
			});

			return output;
		},
		calculateSubTotals: function calculateSubTotals() {
			var self = this;
			var model = this.get('model');
			var categories = this.get('categories');

			Ember['default'].$.each(categories, function (k, category) {

				var obj = { name: category, active: 0, rent: 0, sale: 0, setup: 0 };
				Ember['default'].$.each(model.positions, function (k2, v2) {
					if (v2.category == category) {
						if (v2.active) {
							obj.active += 1;

							if (v2.amount > 0) {
								if (v2.rent) {
									obj.rent += parseFloat(v2.amount) * parseFloat(v2.values_rent_unitprice);
								}

								obj.setup += parseFloat(v2.amount) * parseFloat(v2.values_setup_unitprice);

								if (v2.sale) {
									obj.sale += parseFloat(v2.amount) * parseFloat(v2.values_sale_unitprice);
								}
							}
						}
					}
				});
				self.set('positions.' + k + '.active', obj.active);
				self.set('positions.' + k + '.rent_sum', obj.rent);
				self.set('positions.' + k + '.setup_sum', obj.setup);
				self.set('positions.' + k + '.sale_sum', obj.sale);
			});
		},
		calculateTotals: function calculateTotals() {
			var self = this;
			var model = this.get('model');

			var totals_monthly_net = 0,
			    totals_monthly_vat = 0,
			    totals_monthly_gross = 0,
			    totals_setup_net = 0,
			    totals_setup_vat = 0,
			    totals_setup_gross = 0,
			    totals_buy_net = 0,
			    totals_buy_vat = 0,
			    totals_buy_gross = 0;

			Ember['default'].$.each(model.positions, function (k, v) {

				var pos_rent_sum = 0,
				    pos_sale_sum = 0,
				    pos_setup_sum = 0;

				if (v.active) {

					self.set('model.positions.' + k + '.opacity', '');

					if (v.amount > 0) {
						if (v.rent) {
							pos_rent_sum = parseFloat(v.amount) * parseFloat(v.values_rent_unitprice);
							totals_monthly_net += pos_rent_sum;
						}

						pos_setup_sum += parseFloat(v.amount) * parseFloat(v.values_setup_unitprice);
						totals_setup_net += pos_setup_sum;

						if (v.sale) {
							pos_sale_sum += parseFloat(v.amount) * parseFloat(v.values_sale_unitprice);
							totals_buy_net += pos_sale_sum;
						}

						self.set('model.positions.' + k + '.pos_rent_sum', pos_rent_sum);
						self.set('model.positions.' + k + '.pos_setup_sum', pos_setup_sum);
						self.set('model.positions.' + k + '.pos_sale_sum', pos_sale_sum);
					}
				} else {
					self.set('model.positions.' + k + '.opacity', 'opacity:.3;');
				}
			});

			totals_monthly_vat = totals_monthly_net * 0.19;
			totals_setup_vat = totals_setup_net * 0.19;
			totals_buy_vat = totals_buy_net * 0.19;

			totals_monthly_gross = totals_monthly_net * 1.19;
			totals_setup_gross = totals_setup_net * 1.19;
			totals_buy_gross = totals_buy_net * 1.19;

			this.set("totals_monthly_net", totals_monthly_net);
			this.set("totals_monthly_vat", totals_monthly_vat);
			this.set("totals_monthly_gross", totals_monthly_gross);
			this.set("totals_setup_net", totals_setup_net);
			this.set("totals_setup_vat", totals_setup_vat);
			this.set("totals_setup_gross", totals_setup_gross);
			this.set("totals_buy_net", totals_buy_net);
			this.set("totals_buy_vat", totals_buy_vat);
			this.set("totals_buy_gross", totals_buy_gross);
		},
		parseMisc: function parseMisc() {},
		positions: (function () {
			return this.parsePositions();
		}).property('model.positions.@each'),
		count: 0,
		updatePosition: (function () {
			this.calculateTotals();
			this.calculateSubTotals();
			this.parseMisc();
		}).observes('model.positions.@each', 'model.positions.@each.amount', 'model.positions.@each.active', 'model.positions.@each.sale', 'model.positions.@each.rent', 'model.positions.@each.values_rent_unitprice', 'model.positions.@each.values_sale_unitprice', 'model.positions.@each.values_setup_unitprice'),
		actions: {
			toggle: function toggle(current, index) {
				if (current == 'in') {
					this.set('positions.' + index + '.collapse', '');
				} else {
					this.set('positions.' + index + '.collapse', 'in');
				}
			},
			toggleDescription: function toggleDescription(i, p) {
				var pos = this.get('positions.' + i + '.positions.' + p);

				this.set('positions.' + i + '.positions.' + p + '.showDescription', pos.showDescription ? false : true);
			}
		}
	});

});