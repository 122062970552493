define('client/controllers/users/create', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		needs: ['users'],
		resource: 'user',
		root: 'users',
		createItem: function createItem() {
			this._super();
			this.get('controllers.users').set('refresh', Date.now());
		}
	});

});