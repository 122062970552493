define('client/tests/unit/mixins/info-controller-mixin-test', ['ember', 'client/mixins/info-controller-mixin', 'qunit'], function (Ember, InfoControllerMixinMixin, qunit) {

  'use strict';

  qunit.module('InfoControllerMixinMixin');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var InfoControllerMixinObject = Ember['default'].Object.extend(InfoControllerMixinMixin['default']);
    var subject = InfoControllerMixinObject.create();
    assert.ok(subject);
  });

});