define('client/controllers/contacts/contact/offers', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		root: 'contacts',
		resource: 'contact',
		actions: {
			createOffer: function createOffer() {
				var self = this;
				var model = this.get('model');
				var offer = {
					recipient: model.id,
					opportunity: 0
				};

				this.api.create('offer', { data: JSON.stringify(offer) }).then(function (r) {
					self.notify.info(r.message);
					self.transitionToRoute('offer', r.results);
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			}
		}
	});

});