define('client/helpers/from-now', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.fromNow = fromNow;

  function fromNow(date /*, hash*/) {
    if (moment(date).isValid()) {
      return moment(date).fromNow();
    }
    return '-';
  }

  exports['default'] = Ember['default'].Handlebars.makeBoundHelper(fromNow);

});