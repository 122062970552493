define('client/tests/unit/mixins/model-array-test', ['ember', 'client/mixins/model-array', 'qunit'], function (Ember, ModelArrayMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | model array');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var ModelArrayObject = Ember['default'].Object.extend(ModelArrayMixin['default']);
    var subject = ModelArrayObject.create();
    assert.ok(subject);
  });

});