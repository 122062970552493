define('client/tests/unit/initializers/cache-test', ['ember', 'client/initializers/cache', 'qunit'], function (Ember, cache, qunit) {

  'use strict';

  var container, application;

  qunit.module('CacheInitializer', {
    beforeEach: function beforeEach() {
      Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        container = application.__container__;
        application.deferReadiness();
      });
    }
  });

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    cache.initialize(container, application);

    // you would normally confirm the results of the initializer here
    assert.ok(true);
  });

});