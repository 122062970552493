define('client/helpers/cor-name', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.corName = corName;

    function corName(id /*, hash*/) {

        var res;

        switch (id) {
            case "1":
                res = 'Intern / Notruf';
                break;
            case "2":
                res = 'Ortsbereich';
                break;
            case "4":
                res = 'National';
                break;
            case "5":
                res = 'Europa';
                break;
            case "6":
                res = 'International';
                break;
            case "7":
                res = 'International und Service';
                break;
            default:
                res = id;

        }

        return new Ember['default'].Handlebars.SafeString(res);
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(corName);

});