define('client/helpers/convert-number', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports.convertNumber = convertNumber;

	function convertNumber(number /*, hash*/) {
		var reg = /[,]/;
		if (reg.test(number) === true) {
			return number;
		} else {
			return parseFloat(number).formatNumber(2, '.', ',');
		}
	}

	exports['default'] = Ember['default'].HTMLBars.makeBoundHelper(convertNumber);

});