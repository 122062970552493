define('client/routes/index', ['exports', 'ember', 'client/mixins/no-authentication'], function (exports, Ember, NoAuthenticationMixin) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(NoAuthenticationMixin['default'], {
		titleToken: 'Home',
		beforeModel: function beforeModel() {

			this.transitionTo('login');
		},
		actions: {
			willTransition: function willTransition() {

				this.transitionTo('login');
			}
		}
	});

});