define('client/controllers/activities/create', ['exports', 'ember', 'client/mixins/detail-controller-mixin'], function (exports, Ember, DetailController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(DetailController['default'], {
		resource: 'activity',
		root: 'activities',
		needs: ['activities'],
		createItem: function createItem() {
			var model = this.get('model');
			if (model.activitytype == 'todo') {
				this.set('model.assignedTo', this.data.user.id);
			}
			this._super();
			this.get('controllers.activities').set('refresh', Date.now());
		},
		types: [{ id: 'todo', name: 'Todo' }, { id: 'work', name: 'Work' }, { id: 'log', name: 'Log' }, { id: 'callcenter', name: 'Callcenter' }, { id: 'comment', name: 'Kommentar' }, { id: 'ticket', name: 'Ticket' }],
		checkType: function checkType(type) {
			if (this.get('model.activitytype') == type) {
				return true;
			}
			return false;
		},
		isTodo: (function () {
			return this.checkType('todo');
		}).property('model.activitytype'),
		isWork: (function () {
			return this.checkType('work');
		}).property('model.activitytype'),
		isLog: (function () {
			return this.checkType('log');
		}).property('model.activitytype'),
		isCallcenter: (function () {
			return this.checkType('callcenter');
		}).property('model.activitytype'),
		isComment: (function () {
			return this.checkType('comment');
		}).property('model.activitytype'),
		isTicket: (function () {
			return this.checkType('ticket');
		}).property('model.activitytype'),
		actions: {
			queryContacts: function queryContacts(query, deferred) {
				this.api.query('contact', { firma: { like: query.term, op: "OR" }, name: { like: query.term, op: "OR" } }, [], 1, 10000).then(function (data) {
					//'when everything has been loaded, specify more: false'
					deferred.resolve({ data: data.results, more: false });
				}, deferred.reject);
			}
		}
	});

});