define('client/routes/users/user/orders', ['exports', 'ember', 'client/mixins/subdetail-route-mixin'], function (exports, Ember, DetailRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(DetailRoute['default'], {
		titleToken: 'Orders',
		model: function model() {
			return this.cache.getCache('user');
		}
	});

});