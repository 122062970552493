define('client/services/io', ['exports', 'ember', 'client/config/environment'], function (exports, Ember, AppConfig) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({

		config: {
			host: AppConfig['default'].ioUrl,
			user: null
		},

		instance: null,

		connect: function connect(controller, user) {

			var io = window.io(this.config.host, { query: { user_id: user.id }, 'forceNew': true });
			this.set('config.user', user);

			io.on('news', function (d) {
				controller.notify.info(d);
			});

			this.set('instance', io);
		},

		disconnect: function disconnect() {

			this.instance.disconnect();
		}

	});

});